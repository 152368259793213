import { Conversation as ConversationType } from "@/types/v2/Conversation";
import { Message as MessageType } from "@/types/v2/Message";
import { User } from "@/types/v2";
import {
  MessageThread,
  MessageThreadProps,
} from "@/components/MessageBoard/MessageThread";
import {
  NewMessageInput,
  NewMessageInputProps,
} from "@/components/MessageBoard/NewMessageInput";
import { ClassNameValue } from "tailwind-merge";
import { cn } from "@/lib/utils";

interface ConversationProps
  extends NewMessageInputProps,
    Pick<MessageThreadProps, "onReaction" | "onDelete" | "onEdit"> {
  conversation: ConversationType;
  messages: MessageType[];
  userId: string;
  users: User[];
  className?: ClassNameValue;
}
export const Conversation = ({
  conversation,
  messages,
  userId,
  users,
  onReaction,
  onEdit,
  onDelete,
  className,
  ...props
}: ConversationProps) => {
  return (
    <div
      className={cn(
        "flex flex-col gap-4 w-full flex-grow-0 overflow-y-hidden bg-primary-foreground rounded-lg shadow-lg p-4 text-primary",
        className
      )}
    >
      <h1>{conversation.name}</h1>
      <div className="flex-grow overflow-y-auto">
        <MessageThread
          messages={messages}
          userId={userId}
          users={users}
          onReaction={onReaction}
          onEdit={onEdit}
          onDelete={onDelete}
        />
      </div>
      <NewMessageInput {...props} />
    </div>
  );
};
