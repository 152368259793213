import {
  Sheet as BaseSheet,
  SheetContent,
  SheetDescription,
  SheetHeader,
  SheetTitle,
  SheetTrigger,
} from "@/components/Base/Sheet";
import { PropsWithChildren, ReactNode } from "react";
import { ClassNameValue } from "tailwind-merge";
import { cn } from "@/lib/utils";

interface SheetProps extends PropsWithChildren {
  trigger?: ReactNode;
  title?: string;
  contentClassName?: ClassNameValue;
  headerClassName?: ClassNameValue;
  titleClassName?: ClassNameValue;
  side: "top" | "bottom" | "left" | "right" | null | undefined;
  asChild?: boolean;
}

export const Sheet = ({
  trigger = "Open",
  title,
  children,
  contentClassName,
  headerClassName,
  titleClassName,
  side,
  asChild,
}: SheetProps) => {
  return (
    <BaseSheet>
      <SheetTrigger asChild={asChild}>{trigger}</SheetTrigger>
      <SheetContent
        hideCloseButton
        side={side}
        className={cn(
          "w-full",
          side !== "top" && side !== "bottom" && "max-w-md",
          contentClassName
        )}
      >
        <SheetHeader className={cn(headerClassName)}>
          <SheetTitle className={cn(titleClassName)}>{title}</SheetTitle>
        </SheetHeader>
        <SheetDescription className={"flex flex-grow-0 overflow-hidden"}>
          {children}
        </SheetDescription>
      </SheetContent>
    </BaseSheet>
  );
};
