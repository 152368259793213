export const FEATURE_FLAGS = {
  isCardColorEnabled: "isCardColorEnabled",
  isCardLaborEnabled: "isCardLaborEnabled",
  isCardPrimaryLinkEnabled: "isCardPrimaryLinkEnabled",
  isPromiseTimeEnabled: "isPromiseTimeEnabled",
  isServiceAdvisorFilterEnabled: "isServiceAdvisorFilterEnabled",
  isTechnicianFilterEnabled: "isTechnicianFilterEnabled",
  isSyncV2Enabled: "isSyncV2Enabled",
  isTechnicianLinkEnabled: "isTechnicianLinkEnabled",
  isROLabelLinkEnabled: "isROLabelLinkEnabled",
  isCardColorSyncEnabled: "isCardColorSyncEnabled",
  isManualCardOnlyEnabled: "isManualCardOnlyEnabled",
  isManualCardsEnabled: "isManualCardsEnabled",
  isCardNotesEnabled: "isCardNotesEnabled",
} as const;
export type FEATURE_FLAGS = (typeof FEATURE_FLAGS)[keyof typeof FEATURE_FLAGS];

export type FeatureFlag = {
  allUsers: boolean;
  testUsers: string[];
};

export type FeatureFlags = {
  // eslint-disable-next-line no-unused-vars
  [x in FEATURE_FLAGS]: FeatureFlag;
};

export type FeatureFlagEnabled = {
  [x: string]: boolean;
};
