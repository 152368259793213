import { firestore } from "@/services/firestore";
import { CustomerUpdate } from "@/types/v2/CustomerUpdate";
import { httpsCallables } from "@/models/httpsCallables";

export const customerUpdate = {
  subscribeAll: (
    id: string,
    callback: (doc: CustomerUpdate[] | undefined) => void
  ) => {
    const today = new Date().toISOString().split("T")[0]; // Formats as YYYY-MM-DD

    return firestore.subscribeCollectionWQuery(
      `shops/${id}/customerUpdates`,
      callback,
      "updateDate",
      "==",
      today
    );
  },
  update: async (
    shopId: string,
    customerUpdateId: string,
    customerUpdateData: CustomerUpdate
  ) => {
    console.log(
      "Calling httpsCallables.customerUpdateUpdate for customerUpdateId: ",
      customerUpdateId
    );
    console.log("customerUpdateData: ", customerUpdateData);
    httpsCallables.customerUpdateUpdate({
      shopId,
      customerUpdateId,
      customerUpdateData,
    });
  },
};
