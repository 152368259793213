/**
 * Generates a subdomain URL by combining a given subdomain identifier with a base URL.
 * The subdomain name is normalized to ensure it is valid.
 *
 * @param {string} identifier - The desired identifier for the subdomain. Invalid characters will be removed, spaces will be replaced with hyphens, and the result will be converted to lowercase.
 * @param {string} baseUrl - The base URL to which the subdomain will be appended. Must be a valid URL.
 * @return {string} - The fully constructed subdomain URL.
 */
export function createSubdomainUrl(
  identifier: string,
  baseUrl: string
): string {
  // Normalize the name to create a valid subdomain
  const subdomain = identifier
    .toLowerCase()
    .replace(/\s+/g, "-")
    .replace(/[^a-z0-9-]/g, "");

  // Extract the root domain from the baseUrl
  const url = new URL(baseUrl);
  return `${url.protocol}//${subdomain}.${url.hostname}`;
}
