/**
 * Extracts and returns the initials from a user's display name.
 *
 * @param {string} displayName - The display name of the user. Can be null or undefined.
 * @return {string} The initials extracted from the display name. Returns an empty string if the display name is null or undefined.
 */
export function getUsersInitials(displayName: string | null | undefined) {
  if (!displayName) return "";

  // Split the display name into an array of words
  const words = displayName.trim().split(/\s+/);

  // Extract the first letter from each of the first two words and concatenate them
  return words
    .slice(0, 2)
    .map((word) => word.charAt(0).toUpperCase())
    .join("");
}

/**
 * Extracts the first name from a given display name.
 *
 * @param {string | null | undefined} displayName - The display name from which to extract the first name.
 * @return {string} The first name extracted from the display name or an empty string if the display name is null, undefined, or empty.
 */
export function getUsersFirstName(displayName: string | null | undefined) {
  if (!displayName) return "";
  const words = displayName.trim().split(/\s+/);
  return words[0];
}
