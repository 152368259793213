import { forwardRef } from "react";

export interface SmallColorDialogProps {
  color: string; // Background color of the dialog
  title: string; // Title of the color
  description: string; // Description of the color
}

const SmallColorDialog = forwardRef<HTMLDivElement, SmallColorDialogProps>(
  ({ color, title, description }, ref) => {
    return (
      <div
        ref={ref}
        className="w-[270px] h-[130px] rounded-sm shadow-lg"
        style={{
          backgroundColor: color,
          padding: "20px 25px", // Adds the specified vertical and horizontal padding
        }}
      >
        <div>
          <h2 className="text-base font-bold text-black">{title}</h2>
          <p className="text-sm text-black leading-4">{description}</p>
        </div>
      </div>
    );
  }
);

SmallColorDialog.displayName = "SmallColorDialog";

export { SmallColorDialog };
