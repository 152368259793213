import { forwardRef, useState } from "react";
import { Button } from "@/components/Base/Button";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogTrigger,
} from "@/components/Base/Dialog";
import { SquarePen } from "lucide-react";
import { Label } from "@/components/Base/Label";
import { Input } from "@/components/Base/Input";
import { Textarea } from "@/components/Base/Textarea";

export interface EditColorDialogProps {
  color: string; // The color of the clicked item
  existingTitle?: string; // Existing card title (if any)
  existingDescription?: string; // Existing description (if any)
  onSave?: (title: string, description: string) => void; // Save handler
  onCancel?: () => void; // Cancel handler
}

const EditColorDialog = forwardRef<HTMLDivElement, EditColorDialogProps>(
  ({
    color,
    existingTitle = "",
    existingDescription = "",
    onSave,
    onCancel,
  }) => {
    const [title, setTitle] = useState(existingTitle);
    const [description, setDescription] = useState(existingDescription);
    const [open, setOpen] = useState(false);

    const handleSave = () => {
      if (onSave) {
        onSave(title, description);
      }
      setOpen(false);
    };

    const handleCancel = () => {
      if (onCancel) {
        onCancel();
      }
      setTitle(existingTitle);
      setDescription(existingDescription);
      setOpen(false);
    };

    return (
      <Dialog onOpenChange={setOpen} open={open}>
        <DialogTrigger asChild>
          <Button
            variant="ghost"
            size="sm"
            className="ml-2"
            onClick={() => {
              setOpen(true);
            }}
          >
            <SquarePen style={{ color: "white" }} />
          </Button>
        </DialogTrigger>
        <DialogTitle>Edit Color</DialogTitle>
        <DialogContent className="p-0 w-[270px]">
          <div
            className="h-[60px] w-full rounded-sm"
            style={{ backgroundColor: color }}
          />

          <div className="px-5 py-2">
            <Label htmlFor={"colorInput"}>Card Color Label</Label>
            <Input
              id={"colorInput"}
              type="text"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              placeholder="Card color label"
            />
          </div>

          <div className="px-5 py-2">
            <Label htmlFor={"colorDescription"}>Description</Label>.
            <Textarea
              id={"colorDescription"}
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              placeholder="Color description"
              className={"h-36"}
            />
          </div>

          <div className="px-5 py-4 flex flex-col items-center space-y-3">
            <Button
              variant="default"
              className="w-full h-[40px] bg-[#2D67B2] text-white font-bold text-lg rounded-md hover:opacity-80"
              onClick={handleSave}
            >
              Save
            </Button>
            <Button
              variant="ghost"
              className="w-full text-[#999999] text-sm"
              onClick={handleCancel}
            >
              Cancel
            </Button>
          </div>
        </DialogContent>
      </Dialog>
    );
  }
);

EditColorDialog.displayName = "EditColorDialog";

export { EditColorDialog };
