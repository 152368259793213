import { BuilderAction } from "@/types/v2/Automation";

/**
 * Flattens a hierarchical list of actions by removing nested conditional actions
 * and producing a flat list of actions.
 *
 * @param {BuilderAction[]} actions - The array of actions that may contain nested conditional actions.
 * @return {BuilderAction[]} A flattened array of actions with all nested actions merged into the top-level array.
 */
export function flattenActions(actions: BuilderAction[]): BuilderAction[] {
  let flatList: BuilderAction[] = [];

  for (const action of actions) {
    flatList.push({
      ...action,
      subsequentActions: undefined, // We don't include nested actions in the flat list
    });

    if (action.subsequentActions) {
      const nestedFlatList = flattenActions(action.subsequentActions);
      flatList = flatList.concat(nestedFlatList);
    }
  }

  return flatList;
}
