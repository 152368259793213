import { useStore } from "@/stores/useStore";
import { cn, formatDateToMMDDYYWithTime } from "@/lib/utils";
import { useState, useEffect } from "react";
import { customerUpdate } from "@/models/customerUpdates"; // Import the create function
import { Card, Shop, CustomerUpdate, RepairOrder } from "@/types/v2";

interface CustomerUpdatesProps {
  cardId: string;
  cardData: Card;
  primaryLabel?: string;
  shop?: Shop;
  shopId?: string;
  ticker: number;
}

const WindowSizeMinutes = 30;

type Color = "green" | "blue" | "yellow" | "red" | "gray";

export const CustomerUpdates = ({
  cardData,
  cardId,
  shop,
  shopId,
  ticker,
}: CustomerUpdatesProps) => {
  const integration = useStore((s) => s.integration);
  const customerUpdates = useStore((s) => s.customerUpdates);
  const [currentCardUpdates, setCardUpdates] = useState<CustomerUpdate[]>([]);
  const [updateBubbleColor, setUpdateBubbleColor] = useState<Color[]>([]);
  const [updateCreationInProgress, setUpdateCreationInProgress] =
    useState(false);

  const formattedDate = formatDateToMMDDYYWithTime(
    cardData?.promisedTime ?? null
  );

  const handleUpdateClick = async (update: CustomerUpdate) => {
    if (!shopId || !cardId) return;

    const updateIndex = update.updateIndex;
    const currentUpdate = currentCardUpdates[updateIndex];

    const currentTime = new Date();
    // console.log(">>> D <<<");
    // NEED TO CONVERT DESIRED UPDATE TIME TO LOCAL TIME
    const targetTime = getDateFromDateTimeOrSeconds(update.desiredUpdateTime);
    const timeDifference = currentTime.getTime() - targetTime.getTime();
    const timeDifferenceInMinutes = timeDifference / (1000 * 60);

    if (
      timeDifferenceInMinutes >= -WindowSizeMinutes / 2 &&
      timeDifferenceInMinutes <= WindowSizeMinutes / 2
    ) {
      if (currentUpdate.complete) {
        const updatedUpdate = {
          ...currentUpdate,
          complete: false,
          completedOnTime: false,
        };

        const result = await customerUpdate.update(
          shopId,
          update.id,
          updatedUpdate
        );
        return result;
      } else {
        const updatedUpdate = {
          ...currentUpdate,
          complete: true,
          completedOnTime: true,
        };

        const result = await customerUpdate.update(
          shopId,
          update.id,
          updatedUpdate
        );
        return result;
      }
    } else {
      if (currentUpdate.complete) {
        const updatedUpdate = {
          ...currentUpdate,
          complete: false,
          completedOnTime: false,
        };

        const result = await customerUpdate.update(
          shopId,
          update.id,
          updatedUpdate
        );
        return result;
      } else {
        const updatedUpdate = {
          ...currentUpdate,
          complete: true,
          completedOnTime: false,
        };

        const result = await customerUpdate.update(
          shopId,
          update.id,
          updatedUpdate
        );
        return result;
      }
    }
  };

  // firestoreToDateTime
  // Convert a Firestore timestamp or a Date object to a Date object in local time
  // Tell it that it's UTC - TZDate() {in: utc}

  const getDateFromDateTimeOrSeconds = (
    dateTime: Date | { seconds: number; nanoseconds?: number }
  ): Date => {
    // console.log("dateTime: ", dateTime);
    if (typeof dateTime === "object" && "seconds" in dateTime) {
      const utcDate = new Date(dateTime.seconds * 1000); // Firestore timestamp case
      // console.log("utcDate (from seconds): ", utcDate);
      // console.log("===================");
      return new Date(
        utcDate.toLocaleString("en-US", { timeZone: "America/New_York" })
      ); // Convert to local time
    } else {
      const utcDate = new Date(dateTime); // Date object or date string
      // console.log("utcDate (from date): ", utcDate);
      // console.log("===================");
      return new Date(
        utcDate.toLocaleString("en-US", { timeZone: "America/New_York" })
      ); // Convert to local time
    }
  };

  const getDateFromCardData = (cardData) => {
    const creationTime =
      cardData?.repairOrder?.createdDate ||
      cardData?.createdAt ||
      cardData?.sourceLastUpdated;
    // console.log(">>> A - See if card was created before window. Convert creation time <<<");
    return getDateFromDateTimeOrSeconds(creationTime);
  };

  const createdBeforeWindow = (
    updateTime: Date | { seconds: number; nanoseconds?: number }
  ): boolean => {
    // For each window, we need to see if the card was created after the window. If so,
    // return false. If not, return true.

    const createdDate = getDateFromCardData(cardData);

    // TODO - THIS ONE IS WRONG!!!
    // console.log(">>> B - See if card was created before window. Convert windowStart time <<<");
    // console.log("windowStartTime: ", updateTime);
    const windowStart = getDateFromDateTimeOrSeconds(updateTime);

    if (createdDate > windowStart) {
      return false;
    } else {
      return true;
    }
  };

  useEffect(() => {
    const cardUpdates = customerUpdates.filter(
      (update) => update.cardId === cardId
    );

    cardUpdates.sort((a, b) => {
      // Convert `a.desiredUpdateTime` to a JavaScript timestamp in milliseconds
      const timeA = a.desiredUpdateTime.seconds
        ? a.desiredUpdateTime.seconds * 1000 // Firestore timestamp in seconds
        : new Date(a.desiredUpdateTime).getTime(); // Regular Date or date string

      // Convert `b.desiredUpdateTime` to a JavaScript timestamp in milliseconds
      const timeB = b.desiredUpdateTime.seconds
        ? b.desiredUpdateTime.seconds * 1000 // Firestore timestamp in seconds
        : new Date(b.desiredUpdateTime).getTime(); // Regular Date or date string

      return timeA - timeB;
    });

    setCardUpdates(cardUpdates);

    //     // Initialize the color array with default color values
    //     setUpdateBubbleColor(new Array(cardUpdates.length).fill("gray"));
    //   }
  }, [customerUpdates, shopId, shop, cardId]);

  useEffect(() => {
    if (
      updateCreationInProgress ||
      !customerUpdates ||
      !shopId ||
      !cardId ||
      !shop
    )
      return;

    const currentTime = new Date(); // Local time

    const newColorArray = currentCardUpdates.map((update, index) => {
      // console.log(">>> C - check on ticker by converting desired update time <<<");

      // Convert desired update time to local time
      let targetTime = getDateFromDateTimeOrSeconds(update.desiredUpdateTime);

      // Adjust targetTime to local time zone
      const localTargetTime = new Date(
        targetTime.toLocaleString("en-US", {
          timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone, // User's local time zone
        })
      );

      const timeDifference = currentTime.getTime() - localTargetTime.getTime();
      const timeDifferenceInMinutes = timeDifference / (1000 * 60);
      // console.log("timeDifferenceInMinutes: ", timeDifferenceInMinutes);
      // console.log("update", update);

      if (update.complete) {
        return update.completedOnTime ? "green" : "blue";
      } else {
        if (!createdBeforeWindow(localTargetTime)) {
          return "transparent";
        } else if (
          timeDifferenceInMinutes >= -WindowSizeMinutes / 2 &&
          timeDifferenceInMinutes <= WindowSizeMinutes / 2
        ) {
          return "yellow";
        } else if (
          timeDifferenceInMinutes > WindowSizeMinutes / 2 &&
          createdBeforeWindow(localTargetTime)
        ) {
          return "red";
        } else {
          return "gray";
        }
      }
    });

    setUpdateBubbleColor(newColorArray);
  }, [ticker, currentCardUpdates]);

  return (
    <div className="flex flex-col justify-center items-center space-y-1 max-w-[20%]">
      {currentCardUpdates.map((update, index) => (
        <button
          key={index}
          className="w-6 h-6 rounded-full border-solid border-[1px] border-white"
          style={{ backgroundColor: updateBubbleColor[index] }}
          onClick={() => {
            handleUpdateClick(update);
          }}
        ></button>
      ))}
    </div>
  );
};
