import { addCreationData, createId } from "@/lib/database";
import { generic } from "@/models/generic";
import { firestore } from "@/services/firestore";
import { Automation, CreateAutomationDto } from "@/types/v2/Automation";
import { FirestoreError } from "firebase/firestore";

export const AutomationRepository = {
  path: (shopId: string) => `shops/${shopId}/automations`,
  create: async (shopId: string, automation: CreateAutomationDto) => {
    const automationId = createId();
    const automations = (await AutomationRepository.getAll(shopId)).sort(
      (a, b) => a.sortOrder - b.sortOrder
    );
    const createData: Automation = {
      id: automationId,
      sortOrder: automations.length + 1,
      shopId: shopId,
      enabled: true,
      name: `Automation-${automations.length + 1}`,
      ...automation,
    };
    return generic.create<Automation>(
      addCreationData<Automation>(createData, "client"),
      `${AutomationRepository.path(shopId)}/${automationId}`
    );
  },
  updateSortOrder: async (shopId: string, automations: Automation[]) =>
    Promise.all(
      automations.map((automation, index) => {
        const sortOrder = index + 1;
        if (automation.sortOrder === sortOrder) {
          return;
        }
        return generic.update<Automation>(
          { sortOrder: sortOrder },
          `${AutomationRepository.path(shopId)}/${automation.id}`
        );
      })
    ),
  moveUp: async (shopId: string, automationId: string) => {
    const automations = (await AutomationRepository.getAll(shopId)).sort(
      (a, b) => a.sortOrder - b.sortOrder
    );
    const automationCurrentIndex = automations.findIndex(
      (a) => a.id === automationId
    );
    if (automationCurrentIndex === 0) return;
    const automationPreviousIndex = automationCurrentIndex - 1;
    const automationPrevious = automations[automationPreviousIndex];
    automations[automationPreviousIndex] = automations[automationCurrentIndex];
    automations[automationCurrentIndex] = automationPrevious;
    return AutomationRepository.updateSortOrder(shopId, automations);
  },
  moveDown: async (shopId: string, automationId: string) => {
    const automations = (await AutomationRepository.getAll(shopId)).sort(
      (a, b) => a.sortOrder - b.sortOrder
    );
    const automationCurrentIndex = automations.findIndex(
      (a) => a.id === automationId
    );
    if (automationCurrentIndex === automations.length - 1) return;
    const automationNextIndex = automationCurrentIndex + 1;
    const automationNext = automations[automationNextIndex];
    automations[automationNextIndex] = automations[automationCurrentIndex];
    automations[automationCurrentIndex] = automationNext;
    return AutomationRepository.updateSortOrder(shopId, automations);
  },
  update: async (
    shopId: string,
    automationId: string,
    automationData: Partial<Automation>
  ) => {
    return generic.update<Partial<Automation>>(
      { id: automationId, ...automationData },
      `${AutomationRepository.path(shopId)}/${automationId}`
    );
  },
  delete: (shopId: string, automationId: string) => {
    return generic.delete(automationId, AutomationRepository.path(shopId));
  },
  get: (shopId: string, automationId: string) =>
    generic.get<Automation>(automationId, AutomationRepository.path(shopId)),
  getAll: (shopId: string) => {
    return firestore.queryDocuments<Automation>(
      AutomationRepository.path(shopId),
      "_deleted",
      "!=",
      true,
      undefined,
      1000,
      "sortOrder",
      "asc"
    );
  },
  subscribeAll: (
    shopId: string,
    callback: (doc: Automation[] | undefined) => void,
    onError?: (error: FirestoreError) => void
  ) => {
    return firestore.subscribeCollectionWQueries<Automation>(
      AutomationRepository.path(shopId),
      (docs) => callback(docs.sort((a, b) => a.sortOrder - b.sortOrder)),
      [{ searchParam: "_deleted", opsString: "!=", equality: true }],
      undefined,
      1000,
      "sortOrder",
      "asc",
      onError
    );
  },
};
