import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "@/components/Base/Dialog";
import { Button } from "@/components/Base/Button";

interface ConfirmDialogProps {
  open: boolean;
  setOpen: (arg: boolean) => void;
  message: string;
  onConfirm: () => void;
  loading?: boolean;
}
export const ConfirmDialog = ({
  open,
  setOpen,
  message,
  onConfirm,
  loading,
}: ConfirmDialogProps) => {
  const handleOpenChange = () => setOpen(!open);

  const handleConfirm = async () => {
    onConfirm();
    handleOpenChange();
  };

  return (
    <Dialog open={open} onOpenChange={handleOpenChange}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Are you sure? This cannot be undone</DialogTitle>
        </DialogHeader>
        <DialogDescription>{message}</DialogDescription>
        <DialogFooter className="sm:justify-between">
          <Button
            onClick={handleOpenChange}
            variant={"destructive"}
            disabled={loading}
          >
            Cancel
          </Button>
          <Button onClick={() => handleConfirm()} disabled={loading}>
            Confirm
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};
