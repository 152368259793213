import { Conversation, CreateConversationDto } from "@/types/v2/Conversation";
import { addCreationData, createId } from "@/lib/database";
import { generic } from "@/models/generic";
import { firestore } from "@/services/firestore";

export const ConversationRepository = {
  path: (shopId: string) => `shops/${shopId}/conversations`,
  create: (shopId: string, conversation: CreateConversationDto) => {
    const conversationId = createId();
    const createData: Conversation = {
      id: conversationId,
      shopId: shopId,
      ...conversation,
      lastMessage: null,
      lastMessageAt: null,
      lastMessageId: null,
    };
    return generic.create<Conversation & { id: string }>(
      addCreationData<Conversation>(createData, "client"),
      `${ConversationRepository.path(shopId)}/${conversationId}`
    );
  },
  get: (shopId: string, conversationId: string) =>
    generic.get<Conversation>(
      conversationId,
      ConversationRepository.path(shopId)
    ),
  subscribeAll: (
    shopId: string,
    userId: string,
    callback: (doc: Conversation[] | undefined) => void
  ) => {
    return firestore.subscribeCollectionWQueries<Conversation>(
      ConversationRepository.path(shopId),
      callback,
      [
        { searchParam: "type", opsString: "==", equality: "1:everyone" },
        {
          searchParam: "participants",
          opsString: "array-contains",
          equality: userId,
        },
      ],
      undefined,
      250,
      "updatedAt",
      "desc"
    );
  },
  subscribeMessages: (
    shopId: string,
    conversationId: string,
    callback: (doc: Conversation | undefined) => void
  ) => {
    return firestore.subscribeDocument<Conversation>(
      `${ConversationRepository.path(shopId)}/${conversationId}`,
      callback
    );
  },
};
