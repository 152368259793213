import { CardContents, CARD_FIELD_OPTIONS } from "../../../src/types/v2";

export const DEFAULT_CARD_COLOR = "#E2E2E2";
export const DEFAULT_CONTAINER_ID = "dispatch";
export const DEFAULT_TIMER_VALUE = "15";
export const DEFAULT_CARD_CONFIG: CardContents = {
  primaryField: "Customer Last Name",
  secondaryField: "RO Number",
  tertiaryField: "Key Tag",
};

export const DEFAULT_REFRESH_TIME_IN_SECONDS = 300;

export const SYNCDRIVER_DOC_ID = "syncdriver";

export const DEFAULT_CARD_PRIMARY_FIELD = CARD_FIELD_OPTIONS[0];
export const DEFAULT_CARD_SECONDARY_FIELD = CARD_FIELD_OPTIONS[1];
export const DEFAULT_CARD_TERTIARY_FIELD = CARD_FIELD_OPTIONS[5];
