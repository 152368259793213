import { useStore } from "@/stores/useStore";
import { useEffect } from "react";
import { ConversationRepository } from "@/models/conversation";
import { useNavigate } from "react-router-dom";
import { Spinner } from "@/components/Common/Spinner";

export const MessageBoardRootPage = () => {
  const navigate = useNavigate();
  const { conversations, conversationsLoaded, shopId } = useStore();

  useEffect(() => {
    if (shopId && conversationsLoaded && conversations.length === 0) {
      ConversationRepository.create(shopId, {
        type: "1:everyone",
        name: null,
        participants: [],
      });
    } else if (conversationsLoaded && conversations.length > 0) {
      navigate(`/${shopId}/messageBoard/${conversations[0].id}`);
    }
  }, [conversationsLoaded, conversations, shopId]);

  return <Spinner />;
};
