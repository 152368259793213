import { cn, formatDate } from "@/lib/utils";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/Base/Popover";
import {
  CircleHelpIcon,
  EditIcon,
  MoreHorizontalIcon,
  RocketIcon,
  SmileIcon,
  ThumbsDownIcon,
  ThumbsUpIcon,
  TrashIcon,
} from "lucide-react";
import { useMemo, useState } from "react";
import { Reaction, ReactionType } from "@/types/v2/Message";
import Tooltip from "@/components/Common/Tooltip";
import { Badge } from "@/components/Base/Badge";
import { Button } from "@/components/Base/Button";
import { ClassNameValue } from "tailwind-merge";
import { useUsersContext } from "@/providers/Users.provider";
import { User } from "@/types/v2";
import { UserAvatar } from "@/components/Users/UserAvatar";
import "./NewMessageInput.css";

interface MessageReactionProps {
  reactionType: ReactionType;
  size?: "sm" | "md" | "lg" | "xl";
  onClick?: () => void;
  className?: ClassNameValue;
}

export const MessageReaction = ({
  reactionType,
  size = "md",
  onClick,
  className,
}: MessageReactionProps) => {
  const iconSize = useMemo(() => {
    switch (size) {
      case "sm":
        return 14;
      case "md":
        return 16;
      case "lg":
        return 18;
      case "xl":
        return 20;
      default:
        return 16;
    }
  }, [size]);
  const renderReaction = (reaction: ReactionType) => {
    switch (reaction) {
      case "agree":
        return <RocketIcon size={iconSize} />;
      case "like":
        return <ThumbsUpIcon size={iconSize} />;
      case "dislike":
        return <ThumbsDownIcon size={iconSize} />;
      case "question":
        return <CircleHelpIcon size={iconSize} />;
      case "happy":
        return <SmileIcon size={iconSize} />;
      default:
        return "🤷";
    }
  };
  return (
    <button
      onClick={onClick}
      className={cn(
        size === "sm" && "text-sm",
        size === "md" && "text-base",
        size === "lg" && "text-2xl",
        size === "xl" && "text-4xl",
        onClick ? "hover:opacity-70" : "cursor-default",
        className
      )}
      disabled={!onClick}
    >
      {renderReaction(reactionType)}
    </button>
  );
};

interface MessageReactionsProps {
  reactions: Reaction[];
}
export const MessageReactions = ({ reactions }: MessageReactionsProps) => {
  const { users } = useUsersContext();
  const groupedReactions = useMemo(() => {
    const sections: { [reaction: string]: Reaction[] } = {};
    reactions.forEach((r) => {
      const type = r.type;
      if (!sections[type]) {
        sections[type] = [];
      }
      sections[type].push(r);
    });
    return sections;
  }, [reactions]);

  const formatUsers = (arr: string[]) => {
    const arrToFormat = [...arr];
    if (arrToFormat.length === 1) {
      return <span className={"font-bold"}>{arrToFormat[0]}</span>;
    } else if (arrToFormat.length === 2) {
      return (
        <>
          <span className={"font-bold"}>{arrToFormat[0]}</span> and{" "}
          <span className={"font-bold"}>{arrToFormat[1]}</span>
        </>
      );
    } else {
      const last = arrToFormat.pop();
      return (
        <>
          {arrToFormat.map((user, index) => (
            <span key={index} className={"font-bold"}>
              {user}
              {index < arrToFormat.length - 1 ? ", " : ""}
            </span>
          ))}
          , and <span className={"font-bold"}>{last}</span>
        </>
      );
    }
  };

  const generateMessage = (reactions: Reaction[], reaction: ReactionType) => {
    const reactedUsers = getReactors(reactions);
    const usersString = formatUsers(
      reactedUsers.map((u) => (u ? (u.displayName ?? "") : ""))
    );
    return (
      <p>
        {usersString} reacted with <span className={"italic"}>{reaction}</span>
      </p>
    );
  };

  const getReactors = (reactions: Reaction[]) => {
    const reactedUsers = reactions.map((r) =>
      users.find((u) => u.id === r.userId)
    );

    const uniqueUsers = [];
    for (const user of reactedUsers) {
      if (user && uniqueUsers.findIndex((ru) => ru?.id === user.id) === -1) {
        uniqueUsers.push(user);
      }
    }

    return uniqueUsers;
  };

  return (
    <div className="flex flex-row gap-2">
      {Object.keys(groupedReactions).map((reaction) => (
        <Tooltip
          message={generateMessage(
            groupedReactions[reaction],
            reaction as ReactionType
          )}
          key={reaction}
        >
          {groupedReactions[reaction].length > 2 ? (
            <div className="relative">
              <Badge
                className="absolute -top-1 -right-1 w-3 h-3 text-3xs text-center flex flex-row justify-center items-center"
                variant={"accent"}
                type={"indicator"}
              >
                {groupedReactions[reaction].length}
              </Badge>
              <MessageReaction reactionType={reaction as ReactionType} />
            </div>
          ) : (
            <div className="flex flex-row items-center cursor-default">
              <div className="flex flex-row mr-2 max-w-4">
                {getReactors(groupedReactions[reaction]).map((u) => (
                  <UserAvatar
                    key={u.id}
                    user={u}
                    className="-mr-2 w-4 h-4 text-3xs"
                  />
                ))}
              </div>
              <MessageReaction reactionType={reaction as ReactionType} />
            </div>
          )}
        </Tooltip>
      ))}
    </div>
  );
};

interface MessageContentProps {
  content: string;
  isSender?: boolean;
}

export const MessageContent = ({ content, isSender }: MessageContentProps) => {
  return (
    <div
      className={cn(
        "max-w-md text-sm text-primary py-2 px-4 rounded-md prose",
        isSender ? "bg-accent/10" : "bg-primary/10"
      )}
      dangerouslySetInnerHTML={{ __html: content.replace(/\n/g, "<br/>") }}
    ></div>
  );
};

interface MessageTimeProps {
  timestamp?: Date;
}

export const MessageTime = ({ timestamp }: MessageTimeProps) => {
  if (!timestamp) return null;

  const formattedDate = formatDate(timestamp);

  return <div className="text-xs text-primary/30">{formattedDate}</div>;
};

interface MessageProps
  extends MessageContentProps,
    MessageTimeProps,
    MessageReactionsProps,
    MessageMoreMenuProps {
  sender?: User;
}

interface MessageMoreMenuProps {
  disabled?: boolean;
  className?: ClassNameValue;
  isSender?: boolean;
  onReactionClick?: (reaction: ReactionType) => void;
  onEditClick?: () => void;
  onDeleteClick?: () => void;
}

export const MessageMoreMenu = ({
  disabled,
  className,
  isSender,
  onReactionClick,
  onDeleteClick,
  onEditClick,
}: MessageMoreMenuProps) => {
  const [open, setOpen] = useState(false);
  const handleReactionClick = (reaction: ReactionType) => {
    if (onReactionClick) onReactionClick(reaction);
  };
  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger
        disabled={disabled}
        className={cn(
          "cursor-pointer z-5 p-1 rounded-sm text-accent hover:bg-white/50 disabled:cursor-not-allowed",
          className
        )}
        onMouseDown={(e) => e.stopPropagation()}
        onDoubleClick={(e) => e.stopPropagation()}
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <MoreHorizontalIcon size={14} />
      </PopoverTrigger>
      <PopoverContent
        onMouseDown={(e) => e.stopPropagation()}
        onDoubleClick={(e) => e.stopPropagation()}
        className="flex flex-col gap-2 p-2 bg-accent"
      >
        {isSender ? (
          <div className="flex flex-row gap-2">
            <Button
              size={"sm"}
              variant={"accent"}
              className={"text-xs p-1"}
              onClick={onEditClick}
            >
              <EditIcon size={16} className={"mr-2"} /> Edit
            </Button>
            <Button
              size={"sm"}
              variant={"accent"}
              className={"text-xs p-1"}
              onClick={onDeleteClick}
            >
              <TrashIcon size={16} className={"mr-2"} /> Delete
            </Button>
          </div>
        ) : (
          <div className="flex flex-row gap-3 text-white mx-auto">
            {Object.keys(ReactionType).map((reaction) => (
              <MessageReaction
                onClick={() => handleReactionClick(reaction as ReactionType)}
                key={reaction}
                reactionType={reaction as ReactionType}
                size={"sm"}
              />
            ))}
          </div>
        )}
      </PopoverContent>
    </Popover>
  );
};

export const Message = ({
  sender,
  content,
  timestamp,
  isSender,
  reactions,
  ...props
}: MessageProps) => {
  return (
    <div
      className={cn(
        "flex gap-1 sm:gap-4 w-11/12 sm:w-5/6",
        isSender ? "flex-row-reverse self-end" : "flex-row self-start"
      )}
    >
      <UserAvatar
        user={sender}
        className={"w-6 h-6 sm:w-10 sm:h-10"}
        fallbackClassName={"text-xs sm:text-base"}
      />
      <div className={cn("flex flex-col gap-2", isSender && "items-end")}>
        <MessageContent content={content} isSender={isSender} />
        <div
          className={cn(
            "flex gap-2",
            isSender ? "flex-row-reverse" : "flex-row"
          )}
        >
          <MessageTime timestamp={timestamp} />
          <MessageReactions reactions={reactions} />
        </div>
      </div>
      <MessageMoreMenu
        className={"self-start"}
        isSender={isSender}
        {...props}
      />
    </div>
  );
};
