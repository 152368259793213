import { useState } from "react";
import { Button } from "../Base/Button";
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "../Base/Dialog";
import { container } from "@/models/container";
import { useStore } from "@/stores/useStore";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../Base/Select";
import { PlusIcon, X } from "lucide-react";
import { IntegratorsLabel } from "@/types/v2";

interface LinkCustomLabelDialogProps {
  containerId: string;
  sourceLabel?: string | string[];
  onClose: () => void;
}
export const LinkCustomLabelDialog = ({
  containerId,
  sourceLabel,
  onClose,
}: LinkCustomLabelDialogProps) => {
  const customLabels = useStore((s) => s.shop?.customLabels ?? []);
  const integration = useStore((s) => s.integration);
  const [open, setOpen] = useState(false);
  const [newSourceLabel, setNewSourceLabel] = useState(
    typeof sourceLabel === "string"
      ? [sourceLabel]
      : sourceLabel
        ? sourceLabel
        : []
  );

  console.log({ newSourceLabel, customLabels });

  const handleSave = () => {
    if (!newSourceLabel || newSourceLabel.length === 0) {
      container.deleteContainerLabel(containerId);
    } else {
      container.updateContainerLabel(containerId, newSourceLabel);
    }
    setOpen(false);
    if (onClose) onClose();
  };

  const handleCancel = () => {
    setOpen(false);
    if (onClose) onClose();
  };

  const handleSelectSourceLabel = (label: string, index: number) => {
    const newLabels = [...newSourceLabel];
    newLabels[index] = label;
    setNewSourceLabel(newLabels);
  };

  const handleRemoveSourceLabel = (index: number) => {
    const newLabels = [...newSourceLabel];
    newLabels.splice(index, 1);
    setNewSourceLabel(newLabels);
  };

  const handleAddSourceLabel = () => {
    setNewSourceLabel([...newSourceLabel, ""]);
  };

  return (
    <Dialog onOpenChange={setOpen} open={open}>
      <DialogTrigger asChild>
        <Button onClick={() => setOpen(true)}>
          Link to{" "}
          {integration?.integration
            ? IntegratorsLabel[integration.integration] + " "
            : ""}
          Label
        </Button>
      </DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>
            Edit Container{" "}
            {integration?.integration
              ? IntegratorsLabel[integration.integration] + " "
              : ""}
            Label
          </DialogTitle>
        </DialogHeader>
        <div className="flex flex-col gap-2">
          <div>Please provide a label in the box below.</div>
          {customLabels && (
            <div className="flex flex-col gap-2">
              {newSourceLabel?.map((label, index) => (
                <div
                  className="flex flex-row justify-start gap-4"
                  key={`${label}-${index}`}
                >
                  <Select
                    onValueChange={(value) =>
                      handleSelectSourceLabel(value, index)
                    }
                    value={label ?? ""}
                  >
                    <SelectTrigger className="w-48">
                      <SelectValue placeholder="Pick a Custom Label" />
                    </SelectTrigger>
                    <SelectContent>
                      {customLabels
                        .filter(
                          (l) => !newSourceLabel.includes(l) || l === label
                        )
                        .map((l) => (
                          <SelectItem key={l} value={`${l}`}>
                            {l}
                          </SelectItem>
                        ))}
                    </SelectContent>
                  </Select>
                  <Button
                    size={"icon"}
                    onClick={() => handleRemoveSourceLabel(index)}
                  >
                    <X />
                  </Button>
                </div>
              ))}
            </div>
          )}
          <div className="flex flex-row w-full items-center gap-4">
            <Button size={"icon"} onClick={() => handleAddSourceLabel()}>
              <PlusIcon />
            </Button>
            <span>Click to add another label</span>
          </div>
          <div className="text-xs">
            Note that container custom label changes are global and are
            effective immediately. If you want a custom link to a label for this
            layout only, create a new container. If you do not see a label that
            you expect, make sure that a repair order with that label has been
            synced to Shop Hero.
          </div>
        </div>
        <DialogFooter>
          <Button variant={"secondary"} onClick={handleCancel}>
            Cancel
          </Button>
          <Button onClick={handleSave}>Save</Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};
