import { Button } from "@/components/Base/Button";
import { PlusIcon, TrashIcon } from "lucide-react";
import Tooltip from "@/components/Common/Tooltip";
import { Select } from "@/components/Common/Select";
import { ReactNode, useCallback, useEffect, useState } from "react";
import { Input } from "@/components/Base/Input";
import { Option } from "@/types/v2/UI";
import { TriggerCondition } from "@/types/v2/Automation";
export interface TriggerProps {
  label: string;
  canAdd?: boolean;
  canRemove?: boolean;
  addTooltip?: string;
  removeTooltip?: string;
  onAdd?: () => void;
  onRemove?: () => void;
  conditionalValue?: string;
  conditionalPlaceholder?: string;
  onConditionalChange?: (value: TriggerCondition) => void;
  conditionalOptions?: Option<TriggerCondition>[];
  valuePlaceholder?: string;
  valueValue?: string;
  valueOptions?: Option[];
  onValueChange?: (value: string) => void;
  addComponent?: ReactNode;
  disabled?: boolean;
}

export const Trigger = ({
  label,
  canAdd = true,
  canRemove = true,
  addTooltip = "Add Another",
  removeTooltip = "Remove",
  onAdd,
  onRemove,
  conditionalValue,
  conditionalPlaceholder,
  onConditionalChange,
  conditionalOptions,
  valuePlaceholder,
  valueValue,
  valueOptions,
  onValueChange,
  addComponent,
  disabled,
}: TriggerProps) => {
  const [localState, setLocalState] = useState<{
    conditionalValue: string;
    value: string;
  }>({
    conditionalValue: conditionalValue ?? conditionalOptions?.[0]?.value ?? "",
    value: valueValue ?? "",
  });

  useEffect(() => {
    setLocalState((prevState) => ({
      ...prevState,
      conditionalValue:
        conditionalValue ?? conditionalOptions?.[0]?.value ?? "",
      value: valueValue ?? "",
    }));
  }, [conditionalValue, conditionalOptions, valueValue]);

  const onConditionalValueChangeWrapper = useCallback(
    (value: string) => {
      setLocalState((prevState) => ({
        ...prevState,
        conditionalValue: value,
      }));
      onConditionalChange?.(value as TriggerCondition);
    },
    [onConditionalChange, setLocalState]
  );

  const onValueChangeWrapper = useCallback(
    (value: string) => {
      setLocalState((prevState) => ({
        ...prevState,
        value: value,
      }));
      onValueChange?.(value);
    },
    [onValueChange, setLocalState]
  );

  return (
    <div className={"flex flex-row items-center gap-2"}>
      <div
        className={
          "text-base text-primary flex-grow flex flex-row items-center gap-2 justify-center"
        }
      >
        <p className={"text-center"}>{label}</p>
        <div>
          {conditionalOptions ? (
            <Select
              selectTriggerProps={{
                variant: "minimal",
              }}
              disabled={disabled}
              onValueChange={onConditionalValueChangeWrapper}
              value={localState.conditionalValue}
              placeholder={conditionalPlaceholder}
              options={conditionalOptions}
            />
          ) : (
            <span>{conditionalValue}</span>
          )}
          {onValueChange ? (
            valueOptions && valueOptions.length > 0 ? (
              <Select
                selectTriggerProps={{
                  variant: "minimal",
                }}
                disabled={disabled}
                onValueChange={onValueChangeWrapper}
                value={localState.value}
                placeholder={valuePlaceholder}
                options={valueOptions}
              />
            ) : (
              <Input
                disabled={disabled}
                value={localState.value}
                variant={"minimal"}
                placeholder={valuePlaceholder}
                onChange={(e) => onValueChangeWrapper?.(e.target.value)}
              />
            )
          ) : valueValue ? (
            <span>
              {valueOptions && valueOptions.length > 0
                ? valueOptions.find((o) => o.value === valueValue)?.label
                : valueValue}
            </span>
          ) : null}
        </div>
      </div>
      {(canAdd || canRemove) && (
        <div className={"flex flex-row gap-2"}>
          {canAdd && !addComponent && (
            <Tooltip message={addTooltip}>
              <Button size="icon" variant="ghost" onClick={onAdd}>
                <PlusIcon />
              </Button>
            </Tooltip>
          )}
          {canAdd && addComponent && addComponent}
          {canRemove && (
            <Tooltip message={removeTooltip}>
              <Button size="icon" variant="destructive-alt" onClick={onRemove}>
                <TrashIcon />
              </Button>
            </Tooltip>
          )}
        </div>
      )}
    </div>
  );
};
