import { cn } from "@/lib/utils";
import { TrashIcon } from "lucide-react";
import { ClassNameValue } from "tailwind-merge";
import { Button } from "../Base/Button";
import { useState } from "react";
import { FileUpload } from "../Common/FileUpload";
import { logo } from "@/models/logo";
import { EditSaveButtons } from "../Common/EditSaveButtons";

interface LogoProps {
  logoUrl?: string;
  shopId: string;
  canBeChanged?: boolean;
  className?: ClassNameValue;
  logoClassName?: ClassNameValue;
}

export const Logo = ({
  logoUrl,
  shopId,
  canBeChanged,
  className,
  logoClassName,
}: LogoProps) => {
  const [isEditing, setIsEditing] = useState(false);
  const [futureLogo, setFutureLogo] = useState<File>();
  const [currentLogoUrl, setCurrentLogoUrl] = useState(logoUrl);

  const handleEditClick = () => setIsEditing(true);

  const handleSaveClick = () => {
    setIsEditing(false);

    if (!futureLogo) return;
    logo.upload(shopId, futureLogo);
    setCurrentLogoUrl(URL.createObjectURL(futureLogo)); // Update the current logo with the new one
    setFutureLogo(undefined);
  };

  const handleFileDropped = (file: File) => {
    setFutureLogo(file);
  };

  const handleDiscardClick = () => {
    setFutureLogo(undefined);
    setIsEditing(false);
  };

  const handleDeleteClick = () => {
    logo.delete(shopId); // Assuming a delete method exists in your logo model
    setCurrentLogoUrl(undefined); // Clear the current logo
    setIsEditing(true);
  };

  console.log("logoUrl", logoUrl);
  console.log("isEditing", isEditing);
  console.log("canBeChanged", canBeChanged);
  console.log("futureLogo", futureLogo);
  console.log("====================================");

  return (
    <div className={cn("relative", className)}>
      {currentLogoUrl && !isEditing && (
        <img
          src={currentLogoUrl}
          className={cn(
            "h-48 w-96 m-auto object-contain bg-white rounded-lg",
            logoClassName
          )}
          alt="Logo"
        />
      )}
      {isEditing && (
        <div className="w-96 h-48 m-auto rounded-lg">
          {futureLogo ? (
            <img
              src={URL.createObjectURL(futureLogo)}
              className={cn(
                "h-48 w-full object-contain bg-white ",
                logoClassName
              )}
              alt="Logo"
            />
          ) : currentLogoUrl ? (
            <img
              src={currentLogoUrl}
              className={cn(
                "h-48 w-full object-contain bg-white ",
                logoClassName
              )}
              alt="Logo"
            />
          ) : (
            <FileUpload
              text="Upload or click to browse to new logo"
              handleFileUpload={handleFileDropped}
            />
          )}
        </div>
      )}
      {canBeChanged && (
        <div
          className={cn(
            (currentLogoUrl || isEditing) && "absolute top-2 right-2 ",
            "flex gap-2"
          )}
        >
          {isEditing && currentLogoUrl && (
            <Button
              variant="destructive"
              size="icon"
              onClick={handleDeleteClick}
              className="rounded-full"
            >
              <TrashIcon className="w-5 h-5" />
            </Button>
          )}
          <EditSaveButtons
            isEditing={isEditing}
            handleDiscard={handleDiscardClick}
            handleEdit={handleEditClick}
            handleSave={handleSaveClick}
            saveDisabled={!futureLogo}
          />
        </div>
      )}
    </div>
  );
};
