import { useStore } from "@/stores/useStore";
import { ActivityFeedItem } from "@/components/ActivityFeed/ActivityFeedItem";
import { firestoreToDateTime } from "@/lib/utils";
import { format } from "date-fns";
import { useMemo } from "react";
import { ActivityFeed as ActivityFeedType } from "@/types/v2";

export const ActivityFeed = () => {
  const activityFeed = useStore((s) => s.activityFeed);
  const shop = useStore((s) => s.shop);
  const activityFeedSections = useMemo(() => {
    const groupedItems: { [date: string]: ActivityFeedType[] } = {};
    activityFeed.forEach((item) => {
      const dateTime = firestoreToDateTime(item.createdAt);
      const date = dateTime ? format(dateTime, "MMMM dd, yyyy") : "";
      if (!groupedItems[date]) {
        groupedItems[date] = [];
      }
      groupedItems[date].push(item);
    });

    return Object.keys(groupedItems).map((date) => ({
      sectionName: date,
      items: groupedItems[date],
    }));
  }, [activityFeed]);

  if (!shop) {
    return null;
  }

  return (
    <>
      {activityFeed.length > 0 ? (
        <div className="flex flex-col bg-primary-foreground p-8 pt-2 rounded-lg w-full gap-1 overflow-auto">
          {activityFeedSections.map((section) => (
            <div key={section.sectionName} className="flex flex-col gap-1">
              <div className="text-accent text-3xl font-bold text-center py-4">
                {section.sectionName}
              </div>
              {section.items.map((item) => (
                <ActivityFeedItem key={item.id} shop={shop} item={item} />
              ))}
            </div>
          ))}
        </div>
      ) : (
        <div>
          No activity yet. Move cards on the dashboard to update your activity
          feed.
        </div>
      )}
    </>
  );
};
