import React from "react";
import { BasePage } from "@/components/Page/BasePage";
import { useStore } from "@/stores/useStore";
import {
  RouterProvider as BaseRouterProvider,
  Outlet,
  Route,
  createBrowserRouter,
  createRoutesFromElements,
} from "react-router-dom";
import { Admin } from "@/components/Admin/Admin";
import { Users } from "@/components/Users/Users";
import { SuperAdmin } from "@/components/SuperAdmin/SuperAdmin";
import { Spinner } from "@/components/Common/Spinner";
import { GlobalConfigPage } from "@/pages/(superAdmin)/GlobalConfig.page";
import { ShopConfigPage } from "@/pages/(superAdmin)/(shop)/ShopConfig.page";
import DebugCardCreatePage from "@/pages/(superAdmin)/(debug)/CreateCard.page";
import { AuthState } from "@/types/v1";
import {
  ActivityFeedPage,
  AutomationsPage,
  CardConfigPage,
  CompanyInfoPage,
  DebugPage,
  EmployeesPage,
  ErrorPage,
  IntegrationsPage,
  IsAuthenticatedRedirect,
  LayoutBuilderPage,
  LoginPage,
  ProtectedRoute,
  ResetPasswordPage,
  ShopDashboardPage,
  ShopLayoutPage,
  SignupPage,
  TermsAndConditionsPage,
  VerifyPage,
} from "@/pages";
import { MessageBoardLayoutPage } from "@/pages/(shop)/messageBoard/_layout.page";
import { MessageBoardRootPage } from "@/pages/(shop)/messageBoard/MessageBoardRoot.page";
import { MessageBoardPage } from "@/pages/(shop)/messageBoard/MessageBoard.page";

const router = ({
  authState,
  logoExists,
  logoUrl,
  shopId,
  dashboardKey,
}: {
  authState: AuthState;
  logoExists?: boolean;
  logoUrl?: string;
  shopId?: string;
  dashboardKey?: string;
}) =>
  createBrowserRouter(
    createRoutesFromElements(
      <Route
        path="/"
        errorElement={
          <BasePage>
            <React.Suspense fallback={<Spinner />}>
              <ErrorPage />
            </React.Suspense>
          </BasePage>
        }
        element={
          <BasePage>
            <React.Suspense fallback={<Spinner />}>
              <Outlet />
            </React.Suspense>
          </BasePage>
        }
      >
        {authState === "unauthenticated" && (
          <Route path="" element={<LoginPage />} />
        )}
        {authState === "unverified" && (
          <Route path="" element={<VerifyPage />} />
        )}
        <Route path="/verify" element={<VerifyPage />} />
        <Route path="/signup" element={<SignupPage />} />
        <Route path="/resetpassword" element={<ResetPasswordPage />} />
        <Route path="/terms" element={<TermsAndConditionsPage />} />

        <Route
          path="/:shopId"
          element={
            <ProtectedRoute>
              <ShopLayoutPage />
            </ProtectedRoute>
          }
        >
          <Route path="" element={<ShopDashboardPage />} />
          <Route path="layoutBuilder" element={<LayoutBuilderPage />} />
          <Route path="cardConfig" element={<CardConfigPage />} />
          <Route path="automations" element={<AutomationsPage />} />
          <Route path="employees" element={<EmployeesPage />} />
          <Route path="integrations" element={<IntegrationsPage />} />
          <Route path="companyInfo" element={<CompanyInfoPage />} />
          <Route path="shopInfo" element={<CompanyInfoPage />} />
          <Route path="activityFeed" element={<ActivityFeedPage />} />
          <Route path="messageBoard" element={<MessageBoardLayoutPage />}>
            <Route path="" element={<MessageBoardRootPage />} />
            <Route path=":conversationId" element={<MessageBoardPage />} />
          </Route>
          <Route path="features" element={<ShopConfigPage />} />
        </Route>

        {authState === "authenticated" && (
          <>
            <Route path="/debug" element={<Outlet />}>
              <Route path="" element={<DebugPage />} />
              <Route path="cards" element={<Outlet />}>
                <Route path="create" element={<DebugCardCreatePage />} />
              </Route>
            </Route>
            <Route path="features" element={<Outlet />}>
              <Route path="global" element={<GlobalConfigPage />} />
            </Route>

            <Route
              path=""
              element={
                <ProtectedRoute>
                  <ShopLayoutPage />
                </ProtectedRoute>
              }
            />

            <Route
              path="users"
              element={
                <Users
                  logoExists={logoExists}
                  logoUrl={logoUrl}
                  shopId={shopId}
                  dashboardKey={dashboardKey}
                />
              }
            />

            {/* <Route
          path="billing"
          element={
            <Billing
              logoExists={logoExists}
              logoUrl={logoUrl}
              shopId={shopId}
              dashboardKey={dashboardKey}
            />
          }
        /> */}

            <Route
              path="admin"
              element={
                <Admin
                  logoExists={logoExists}
                  logoUrl={logoUrl}
                  shopId={shopId}
                />
              }
            />

            <Route path="superAdmin" element={<SuperAdmin />} />
          </>
        )}
        <Route path="*" element={<IsAuthenticatedRedirect />} />
      </Route>
    )
  );
export const RouterProvider = () => {
  const authState = useStore((s) => s.authState);
  const { logoExists, logoUrl, shopId } = useStore();

  return (
    <BaseRouterProvider
      router={router({
        authState,
        logoExists,
        logoUrl,
        shopId,
        dashboardKey: "101",
      })}
    />
  );
};
