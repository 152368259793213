import { addCreationData, createId } from "@/lib/database";
import { generic } from "@/models/generic";
import { firestore } from "@/services/firestore";
import { ConversationRepository } from "@/models/conversation";
import {
  CreateMessageDto,
  Message,
  PatchMessageDto,
  ReactionType,
} from "@/types/v2/Message";

export const MessageRepository = {
  path: (shopId: string, conversationId: string) =>
    `${ConversationRepository.path(shopId)}/${conversationId}/messages`,
  create: (
    shopId: string,
    conversationId: string,
    message: CreateMessageDto
  ) => {
    const messageId = createId();
    const createData: Message = {
      id: messageId,
      shopId: shopId,
      conversationId: conversationId,
      ...message,
      readBy: [],
      reactions: [],
    };
    return generic.create<Message & { id: string }>(
      addCreationData<Message>(createData, "client"),
      `${MessageRepository.path(shopId, conversationId)}/${messageId}`
    );
  },
  get: (shopId: string, conversationId: string, messageId: string) =>
    generic.get<Message>(
      messageId,
      MessageRepository.path(shopId, conversationId)
    ),

  delete: (shopId: string, conversationId: string, messageId: string) =>
    generic.delete(
      messageId,
      MessageRepository.path(shopId, conversationId),
      true
    ),
  update: async (
    shopId: string,
    conversationId: string,
    messageId: string,
    messageData: PatchMessageDto
  ) => {
    try {
      return await firestore.updateDocument(
        `${MessageRepository.path(shopId, conversationId)}/${messageId}`,
        messageData
      );
    } catch (e) {
      console.log(e);
      throw e;
    }
  },
  addRemoveReaction: (
    shopId: string,
    conversationId: string,
    message: Message,
    userId: string,
    reaction: ReactionType
  ) => {
    const updateMessage = { ...message };
    const updatedReactions = updateMessage.reactions;

    const reactionIndex = updatedReactions.findIndex(
      (r) => r.userId === userId && r.type === reaction
    );
    if (reactionIndex !== -1) {
      updatedReactions.splice(reactionIndex, 1);
    } else {
      updatedReactions.push({ userId, type: reaction });
    }

    return MessageRepository.update(shopId, conversationId, message.id, {
      ...updateMessage,
      reactions: updatedReactions,
    });
  },
  subscribeAll: (
    shopId: string,
    conversationId: string,
    callback: (doc: Message[] | undefined) => void
  ) => {
    console.log("subscribing to messages", {
      shopId,
      conversationId,
    });
    return firestore.subscribeCollection<Message>(
      MessageRepository.path(shopId, conversationId),
      callback,
      undefined,
      250,
      "createdAt",
      "desc"
    );
  },
};
