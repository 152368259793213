import { useField } from "formik";
import { Label } from "@/components/Base/Label";
import { Error } from "@/components/Base/Error";
import { SelectProps } from "@radix-ui/react-select";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/Base/Select";
import { cn } from "@/lib/utils";

type Option = {
  label: string;
  value: string;
};
interface FormikSelectProps extends SelectProps {
  name: string;
  id?: string;
  placeholder?: string;
  label?: string;
  options: Option[];
  labelSrOnly?: boolean;
}

export const FormikSelect = ({
  label,
  labelSrOnly,
  ...props
}: FormikSelectProps) => {
  const [field, meta, helpers] = useField(props);
  return (
    <div>
      {label && (
        <Label htmlFor={props.id} className={cn(labelSrOnly && "sr-only")}>
          {label}
        </Label>
      )}
      <Select {...field} onValueChange={(value) => helpers.setValue(value)}>
        <SelectTrigger
          className="w-full text-primary"
          disabled={props.disabled}
        >
          <SelectValue placeholder={props.placeholder} />
        </SelectTrigger>
        <SelectContent>
          {props.options.map((option, index) => (
            <SelectItem key={`${option.value}-${index}`} value={option.value}>
              {option.label}
            </SelectItem>
          ))}
        </SelectContent>
      </Select>
      {meta.touched && meta.error ? <Error error={meta.error} /> : null}
    </div>
  );
};
