import { useEffect, useState } from "react";
import GridLayout from "./GridLayout";
import { layout as layoutModel } from "@/models/layout";
import { useStore } from "@/stores/useStore";
import { LayoutAccess } from "@/types/v2";
import { shop } from "@/models/shop";

import { addLayoutToLayouts } from "../../data/Layouts/addLayoutToLayouts";
import { addContainerToContainers } from "../../data/Containers/addContainerToContainers";
import { useQueryClient } from "react-query";
import { updateLayoutsArray } from "../../data/Layouts/updateLayoutsArray";
import clone from "just-clone";
import { setStatusesToFetch as setStatusesToCollect } from "../../data/Context/shop";
import { EditContainerName } from "../Dialogs/EditContainerName";
import { updateContainers } from "../../data/Containers/updateContainers";
import { ContainerHasItemsWarning } from "../Dialogs/ContainerHasItemsWarning";
import { overwriteLayoutsArray } from "../../data/Layouts/overwriteLayoutsArray";
import { Button } from "@/components/Base/Button";
import { Input } from "@/components/Base/Input";
import {
  Select,
  SelectTrigger,
  SelectValue,
  SelectContent,
  SelectItem,
} from "@/components/Base/Select";
import { LayoutAccessDialog } from "@/components/Dialogs/LayoutAccessDialog";
import { NewLayoutDialog } from "@/components/Dialogs/NewLayoutDialog";
import { showErrorToast, showSuccessToast } from "@/lib/toast";
import { Trash2, CirclePlus, Pencil } from "lucide-react";
import Tooltip from "@/components/Common/Tooltip";

export const LayoutCreator = () => {
  const shopData = useStore((s) => s.shop);
  const [layout, setLayout] = useState(layoutModel.generateBaseLayout());
  const shopId = useStore((s) => s.shopId);

  const [layouts, setLayouts] = useState([]);
  const [containerIds, setContainerIds] = useState([]);
  const [newContainerName, setNewContainerName] = useState("");
  const [newLayoutName, setNewLayoutName] = useState("");
  const [originalContainerName, setOriginalContainerName] = useState("");
  const [editContainerName, setEditContainerName] = useState("");
  const [editContainerOpen, setEditContainerOpen] = useState(false);
  const [containerHasItemsWarningOpen, setContainerHasItemsWarningOpen] =
    useState(false);
  const [layoutAccess, setLayoutAccess] = useState<LayoutAccess>();

  const layoutAccessArray = shopData?.layoutAccess;

  const queryClient = useQueryClient();

  useEffect(() => {
    if (shopData) {
      const containerIds = shopData.containers.map((container) => container.id);
      setContainerIds(containerIds);
      setLayouts(shopData?.layouts);
      if (shopData?.statusesToSync.length > 0) {
        const statusesAsStrings = shopData?.statusesToSync.map(
          (status) => status
        );
        setStatusesToCollect(statusesAsStrings);
      }
    }
  }, [shopData]);

  const createContainerFromExisting = (containerName) => {
    const layoutCopy = layout.slice();
    if (layoutCopy.find((layout) => layout.i === containerName) === undefined) {
      const newLayout = layoutCopy.concat({
        i: containerName,
        x: 0,
        y: 0,
        w: 16,
        h: 6,
      });
      setLayout(newLayout);
    } else {
      showErrorToast(
        "Container Already Exists",
        "Container already exists in this layout"
      );
    }
  };

  const deleteExistingContainer = (containerName) => {
    const containersClone = clone(shopData?.containers);
    const layoutsClone = clone(layouts);
    const containerToEdit = containersClone.find(
      (container) => container.id === containerName
    );
    const containerIndex = containersClone.findIndex(
      (container) => container.id === containerName
    );
    if (containerName === "dispatch") {
      showErrorToast(
        "Dispatch Container",
        "Dispatch container cannot be deleted"
      );
      return;
    }
    if (
      window.confirm(
        "THIS WILL REMOVE THE CONTAINER FROM ALL EXISTING LAYOUTS AND CANNOT BE UNDONE. Are you sure you want to delete this container from all layouts?"
      )
    ) {
      // CHECK IF CONTAINER IS EMPTY
      const containerHasItems = containerToEdit.tags.length > 0;

      // IF CONTAINER IS NOT EMPTY, PROMPT USER TO EMPTY CONTAINER FIRST
      if (containerHasItems) {
        setContainerHasItemsWarningOpen(true);
        return;
      }

      const removeContainerNameFromLayouts = (name) => (layout) => {
        return Object.entries(layout).map(([key, value]) => ({
          [key]: value.filter(({ i }) => i !== name),
        }));
      };

      //console.log(layoutsClone.map(removeContainerNameFromLayouts(containerName)))
      //return

      // IF CONTAINER IS EMPTY, DELETE CONTAINER FROM FIRESTORE
      containersClone.splice(containerIndex, 1);
      const payload = {};
      payload.shopId = shopId;
      payload.containers = containersClone;
      const updatedLayouts = layoutsClone
        .map(removeContainerNameFromLayouts(containerName))
        .reduce((acc, curr) => [...acc, ...curr], []);
      updateContainers(payload);
      updateLayoutsArray({
        shopId,
        layouts: updatedLayouts,
      }).then(() => {
        if (newLayoutName !== "") {
          // console.log(newLayoutName, updatedLayouts[0].hasOwnProperty(newLayoutName))
          const selectedLayout = updatedLayouts.find((layout) =>
            layout.hasOwnProperty(newLayoutName)
          );
          setLayout(selectedLayout[newLayoutName]);
        }
        queryClient.refetchQueries(["shop"]);
        showSuccessToast(
          "Layout Updated",
          "The layout has been updated successfully."
        );
      });
    }
  };

  const openEditContainerNameDialog = (containerName) => {
    const containersClone = clone(shopData?.containers);
    const containerToEdit = containersClone.find(
      (container) => container.id === containerName
    );

    if (containerName === "dispatch") {
      showErrorToast(
        "Dispatch Container",
        "Dispatch container cannot be edited"
      );
      return;
    }
    // CHECK IF CONTAINER IS EMPTY
    // const containerHasItems = containerToEdit.tags?.length > 0;

    // IF CONTAINER IS NOT EMPTY, PROMPT USER TO EMPTY CONTAINER FIRST
    // if (containerHasItems) {
    //   setContainerHasItemsWarningOpen(true);
    //   return;
    // }
    // IF CONTAINER IS EMPTY, EDIT CONTAINER
    setOriginalContainerName(containerName);
    setEditContainerOpen(true);
  };

  //HERE
  const editExistingContainer = (containerName) => {
    const containersClone = clone(shopData?.containers);
    const layoutsClone = clone(layouts);
    const containerToEdit = containersClone.find(
      (container) => container.id === containerName
    );
    const containerIndex = containersClone.findIndex(
      (container) => container.id === containerName
    );

    const editContainerNameFromLayouts = (name) => (layout) => {
      return Object.entries(layout).map(([key, value]) => ({
        [key]: value.map((v) =>
          v.i === name ? { ...v, i: editContainerName } : v
        ),
      }));
    };

    const containerClone = clone(containerToEdit);
    containerClone.id = editContainerName;
    containersClone[containerIndex] = containerClone;
    const payload = {};
    payload.shopId = shopId;
    payload.containers = containersClone;

    const updatedLayouts = layoutsClone
      .map(editContainerNameFromLayouts(containerName))
      .reduce((acc, curr) => [...acc, ...curr], []);

    updateContainers(payload).then(() => {
      const containerIds = containersClone.map((container) => container.id);
      setContainerIds(containerIds);
    });
    updateLayoutsArray({
      shopId,
      layouts: updatedLayouts,
    }).then(() => {
      if (newLayoutName !== "") {
        // console.log(newLayoutName, updatedLayouts[0].hasOwnProperty(newLayoutName))
        const selectedLayout = updatedLayouts.find((layout) =>
          layout.hasOwnProperty(newLayoutName)
        );
        setLayout(selectedLayout[newLayoutName]);
      }
      queryClient.refetchQueries(["shop"]);
      showSuccessToast(
        "Layout Updated",
        "The layout has been updated successfully."
      );
    });
  };

  const createContainer = () => {
    if (containerIds.find((id) => id === newContainerName)) {
      showErrorToast(
        "Container Already Exists",
        "Cannot create container with same name as existing container"
      );
      return;
    }

    if (newContainerName.length > 0) {
      const layoutCopy = layout.slice();
      const newLayout = layoutCopy.concat({
        i: newContainerName,
        x: 0,
        y: 0,
        w: 16,
        h: 6,
      });
      const payload = {
        shopId: shopId,
        newContainerName: newContainerName,
      };
      addContainerToContainers(payload);
      setLayout(newLayout);
      setNewContainerName("");
      showSuccessToast(
        "Container Created",
        "The container has been created successfully."
      );
    }
  };

  const removeItem = (id: string) => {
    const layoutCopy = layout.slice();
    const newLayout = layoutCopy.filter((item) => item.i !== id);
    setLayout(newLayout);
  };

  const onLayoutChange = (layout) => {
    setLayout(layout);
  };

  const handleSelectLayout = (layoutName: string) => {
    if (layoutName === "placeholder") return; // Ignore placeholder selection
    const layoutIndex = layouts.findIndex(
      (layout) => Object.keys(layout)[0] === layoutName
    );

    const newLayoutObj = layouts[layoutIndex];
    const keys = Object.keys(newLayoutObj);
    setNewLayoutName(layoutName);
    const layout = newLayoutObj[keys[0]];
    setLayout(layout);
    setLayoutAccess(
      layoutAccessArray?.find((access) => access.layoutName === layoutName)
    );
  };

  const handleLayoutDelete = () => {
    if (
      window.confirm(
        "THIS WILL REMOVE THE LAYOUT AND CANNOT BE UNDONE. Are you sure you want to delete this layout?"
      )
    ) {
      const layoutsClone = clone(layouts);
      const layoutNames = layouts.map(Object.keys).flat();
      const layoutIndex = layoutNames.indexOf(newLayoutName);
      layoutsClone.splice(layoutIndex, 1);
      const payload = {
        shopId: shopId,
        layouts: layoutsClone,
      };
      overwriteLayoutsArray(payload).then(() => {
        showSuccessToast(
          "Layout Deleted",
          "The layout has been deleted successfully."
        );
        setNewLayoutName("");
      });
    }
  };

  const saveLayout = (layoutToSave: string, isNew?: boolean) => {
    // WRITE LAYOUTS TO FIRESTORE
    const layoutNames = layouts.map(Object.keys).flat();
    const layoutAlreadyExists = layoutNames.includes(layoutToSave);

    const collector = layout.map((containerObj) =>
      Object.fromEntries(
        Object.entries(containerObj).filter(([_, value]) => value !== undefined)
      )
    );

    const payload = {
      name: layoutToSave,
      layout: collector,
      shopId: shopId,
    };

    console.log("layoutAlreadyExists", layoutAlreadyExists);
    // If the layout does not already exist, add it to the layouts in Firestore
    if (!layoutAlreadyExists) {
      console.log("Adding new layout to Firestore", payload);
      addLayoutToLayouts({
        ...payload,
        layout: layoutModel.generateBaseLayout(),
      }).then((ret) => {
        console.log("ret", ret);
        if (ret === "success") {
          queryClient.refetchQueries("shop");
          showSuccessToast(
            "Layout Saved",
            "The layout has been saved successfully."
          );
        } else {
          showErrorToast(
            "Error Saving Layout",
            "There was an error saving the layout."
          );
        }
      });
    } else {
      const layoutsClone = clone(layouts);
      const layoutIndex = layoutNames.indexOf(layoutToSave);
      const newLayoutObj = { [layoutToSave]: collector };
      layoutsClone[layoutIndex] = newLayoutObj;

      const updatePayload = {
        shopId: shopId,
        layouts: layoutsClone,
      };
      // console.log(updatePayload);

      updateLayoutsArray(updatePayload).then((ret) => {
        console.log("ret", ret);
        queryClient.refetchQueries(["shop"]);
        showSuccessToast(
          "Layout Saved",
          "The layout has been saved successfully."
        );
      });
    }
  };

  const regenerateLayoutAccessArray = (
    layoutName: string,
    updatedAllowedList: string[]
  ) => {
    const updatedLayoutAccessArray = clone(layoutAccessArray || []);
    const layoutIndex = updatedLayoutAccessArray.findIndex(
      (access) => access.layoutName === layoutName
    );

    if (layoutIndex >= 0) {
      updatedLayoutAccessArray[layoutIndex].allowedList = updatedAllowedList;
    } else {
      updatedLayoutAccessArray.push({
        layoutName,
        allowedList: updatedAllowedList,
      });
    }

    return updatedLayoutAccessArray;
  };

  const handleLayoutAccessUpdate = (
    layoutName: string,
    updatedAllowedList: string[]
  ) => {
    if (!shopId) return;

    const newLayoutAccessArray = regenerateLayoutAccessArray(
      layoutName,
      updatedAllowedList
    );

    // Update the shop document with the new array
    shop
      .updateLayoutAccess(shopId, newLayoutAccessArray)
      .then(() => {
        showSuccessToast(
          "Layout Access Updated",
          "The layout access has been updated successfully."
        );
      })
      .catch((error) => {
        showErrorToast(
          "Error Updating Layout Access",
          "There was an error updating the layout access. Please try again."
        );
      });
  };

  return (
    <>
      <div className="flex flex-row">
        <p>Having trouble with your layout? </p>
        <a
          className="text-red underline ml-1 mr-1"
          href="https://www.shopherodashboard.com/support-request"
          target="_blank"
        >
          {" Contact"}
        </a>
        <p>a Layout Hero.</p>
      </div>
      <div className="flex flex-row content-between px-[100px] items-start"></div>
      {/* Whiteboard Configuration */}
      <div className="flex flex-row content-between px-[100px] items-start mt-10">
        {/* Layout Managmenet */}
        <div className="border border-primary-foreground p-5 rounded-xl mr-2 flex flex-col gap-4">
          <div className="flex-1 text-2xl">Layout Management</div>
          <div className="flex flex-row gap-2">
            <Select
              onValueChange={handleSelectLayout}
              value={newLayoutName || "placeholder"} // Use "placeholder" if no layout is selected
            >
              <SelectTrigger className="h-12 w-60 text-primary">
                <SelectValue placeholder="Select Existing Layout" />
              </SelectTrigger>
              <SelectContent className="border border-black rounded-md shadow-lg">
                <SelectItem
                  value="placeholder"
                  disabled
                  className="text-gray-400"
                >
                  Select Existing Layout
                </SelectItem>
                {layouts.map((obj) => {
                  const name = Object.keys(obj)[0];
                  return (
                    <SelectItem
                      key={name}
                      value={name} // Use the name as the value
                      className="text-primary hover:bg-blue-500 hover:text-white"
                    >
                      {name} {/* Display the name */}
                    </SelectItem>
                  );
                })}
              </SelectContent>
            </Select>

            <Button
              disabled={newLayoutName?.length === 0}
              onClick={handleLayoutDelete}
              size="lgIcon"
              variant={"destructive"}
              className={"disabled:bg-zinc-700"}
            >
              <Trash2 />
            </Button>
            <NewLayoutDialog
              newLayout={false}
              layoutName={newLayoutName}
              existingLayoutNames={layouts.map(Object.keys).flat()}
              onConfirm={(theNewLayoutName: string) => {
                setNewLayoutName(theNewLayoutName);
                console.log("theNewLayoutName", theNewLayoutName);
                saveLayout(theNewLayoutName);
                handleSelectLayout(theNewLayoutName);
              }}
            />
            <LayoutAccessDialog
              layoutAccess={layoutAccess}
              onConfirm={(updatedAllowedList) => {
                handleLayoutAccessUpdate(newLayoutName, updatedAllowedList);
              }}
              disabled={newLayoutName.length === 0}
            />
          </div>

          <div className="flex flex-row">
            <NewLayoutDialog
              newLayout={true}
              layoutName={""}
              existingLayoutNames={layouts.map(Object.keys).flat()}
              onConfirm={(theNewLayoutName: string) => {
                setNewLayoutName(theNewLayoutName);
                console.log("theNewLayoutName", theNewLayoutName);
                saveLayout(theNewLayoutName);
                handleSelectLayout(theNewLayoutName);
              }}
            />
          </div>
        </div>

        <div className="border border-primary-foreground p-5 rounded-xl flex flex-col gap-4">
          <div className="flex-1 text-2xl">Container Management</div>
          <div className="flex flex-col gap-4">
            <Input
              type="text"
              placeholder="Container Name"
              value={newContainerName}
              onChange={(e) => setNewContainerName(e.target.value)}
              className="color-[#000000] h-12 w-full rounded-md text-primary"
            />
            <Button onClick={createContainer} variant={"accent"} size={"xl"}>
              Create New Container
            </Button>
          </div>
        </div>

        <div className="mb-7 flex flex-row content-between px-[100px] items-start mt-10">
          {/* Container Managmenet */}
        </div>
      </div>
      <div className="ml-[100px] mr-[100px] border border-primary-foreground p-5 rounded-xl m-2.5 flex flex-col gap-4">
        <p className="font-bold pr-5">Container List:</p>
        <div className="pr-8 flex flex-row flex-wrap">
          {containerIds.map((id) => {
            return (
              <div
                key={id}
                className="mr-5 mb-2.5 min-w-[275px] max-w-[275px] text-base flex flex-row items-center"
              >
                <Tooltip message="add this container to layout">
                  <Button
                    onClick={() => {
                      createContainerFromExisting(id);
                    }}
                    className="p-1"
                  >
                    <CirclePlus />
                  </Button>
                </Tooltip>

                <Tooltip message="delete this container">
                  <Button
                    onClick={() => {
                      deleteExistingContainer(id);
                    }}
                    className="p-1"
                  >
                    <Trash2 />
                  </Button>
                </Tooltip>

                <Tooltip message="edit container name">
                  <Button
                    onClick={() => {
                      openEditContainerNameDialog(id);
                    }}
                    className="p-1"
                  >
                    <Pencil />
                  </Button>
                </Tooltip>

                <div className="ml-2.5">{id}</div>
              </div>
            );
          })}
        </div>
      </div>
      <div className="flex flex-col w-full">
        <Button
          className="bg-[#244C91] text-white ml-2 mr-2 text-xl h-20"
          disabled={newLayoutName?.length === 0}
          onClick={() => saveLayout(newLayoutName)}
        >
          {`Save Layout "${newLayoutName}"`}
        </Button>
      </div>
      <div className="text-black">
        <GridLayout
          layout={layout}
          onLayoutChange={onLayoutChange}
          removeItem={removeItem}
        />
      </div>
      <EditContainerName
        editContainerName={editContainerName}
        setEditContainerName={setEditContainerName}
        editContainerOpen={editContainerOpen}
        setEditContainerOpen={setEditContainerOpen}
        editExistingContainer={editExistingContainer}
        originalContainerName={originalContainerName}
      />
      <ContainerHasItemsWarning
        containerHasItemsWarningOpen={containerHasItemsWarningOpen}
        setContainerHasItemsWarningOpen={setContainerHasItemsWarningOpen}
      />
    </>
  );
};
