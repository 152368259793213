import { DragHandleProps } from "./Card.DragHandle";
import { Card, Shop } from "@/types/v2";
import { CardDetails } from "@/components/Dashboard/components/Card/Card.Details";
import { CustomerUpdates } from "@/components/Dashboard/components/Card/Card.CustomerUpdates";

interface CardMainProps extends DragHandleProps {
  cardId: string;
  cardData: Card;
  primaryLabel?: string;
  shop?: Shop;
  shopId?: string;
  ticker: number;
}

export const CardMain = ({
  cardData,
  cardId,
  shop,
  shopId,
  ticker,
  dndAttributes,
  dndListeners,
}: CardMainProps) => {
  return (
    <div className="px-1 flex flex-row justify-between overflow-hidden">
      <CardDetails
        cardData={cardData}
        cardId={cardId}
        shop={shop}
        shopId={shopId}
        ticker={ticker}
        dndAttributes={dndAttributes}
        dndListeners={dndListeners}
      />
      {shop?.customerUpdateEnabled && shop?.customerUpdateEnabled === "yes" && (
        <CustomerUpdates
          cardData={cardData}
          cardId={cardId}
          shop={shop}
          shopId={shopId}
          ticker={ticker}
        />
      )}
    </div>
  );
};
