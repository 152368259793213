import { useEffect, useState } from "react";
import { AutomationRepository } from "@/models/automation";
import { useStore } from "@/stores/useStore";
import { Automation, CreateAutomationDto } from "@/types/v2/Automation";
import { FirestoreError } from "firebase/firestore";
import { Spinner } from "@/components/Common/Spinner";
import { AutomationItem } from "@/components/Automations/AutomationItem";
import { SupportLink } from "@/components/Common/SupportLink";
import { AutomationDialog } from "@/components/Automations/AutomationDialog";

export const AutomationsConfiguration = () => {
  const shopId = useStore((s) => s.shopId);
  const [automations, setAutomations] = useState<Automation[]>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<FirestoreError | null>(null);

  useEffect(() => {
    const unsubscribe = shopId
      ? AutomationRepository.subscribeAll(
          shopId,
          (data) => {
            setLoading(true);
            setAutomations(data ?? []);
            setError(null);
            setLoading(false);
          },
          (error) => setError(error)
        )
      : () => {};
    return () => unsubscribe();
  }, [shopId]);

  const handleCreate = async (automation: CreateAutomationDto) => {
    if (!shopId) return;
    setLoading(true);
    try {
      await AutomationRepository.create(shopId, automation);
    } catch (error) {
      console.error(error);
    }
    setLoading(false);
  };

  console.log({ automations });

  if (loading || !shopId) return <Spinner />;

  if (error) return <div>{error.message}</div>;

  return (
    <div className="space-y-2 pl-4 overflow-y-auto">
      <h2 className="text-lg font-bold pt-2">About Automations</h2>
      <p>
        Automations are an integral feature of ShopHero Dashboard. They empower
        you to automate numerous manual tasks related to card management,
        enabling an optimised, efficient and add a touch of
        &#39;intelligence&#39; to your workflow. With automations, you can
        configure a variety of triggers and conditions that when met, activate
        certain actions on the cards.
      </p>
      <p>
        For instance, an automation can be set up such that when a card is moved
        to a specific container, the card&#39;s color changes automatically.
        This allows for visual cues to be set up, aiding in better organization
        and understanding of tasks at a simple glance.
      </p>
      <p>
        Additionally, automations are designed to be flexible and intelligent.
        It includes conditional checks in triggers, permitting precision and
        control. For example, you may want a card action to only trigger when
        the card is moved to a specific container named &#39;INSPECTION&#39;.
        The automatic checks will validate this condition before executing the
        action.
      </p>
      <p>
        In essence, these automations are designed to provide an automated,
        precise, and intelligent system, keeping you in control while maximizing
        efficiency
      </p>
      <p>
        Need help setting up automations? Click <SupportLink>here</SupportLink>
      </p>
      <AutomationDialog onSubmit={handleCreate} />
      <h2 className="text-lg font-bold pt-8">Current Automations</h2>
      {automations.map((automation) => (
        <AutomationItem
          automation={automation}
          key={automation.id}
          shopId={shopId}
          canMoveDown={automations.indexOf(automation) < automations.length - 1}
          canMoveUp={automations.indexOf(automation) > 0}
        />
      ))}
    </div>
  );
};
