export const Integrators = {
  tekmetric: "tekmetric",
  shopware: "shopware",
  manual: "manual",
} as const;

export type Integrators = (typeof Integrators)[keyof typeof Integrators];

// eslint-disable-next-line no-unused-vars
export const IntegratorsLabel: { [key in Integrators]: string } = {
  tekmetric: "Tekmetric",
  shopware: "Shop-Ware",
  manual: "No Integration",
};

export type Integration = {
  integration: Integrators;
  linkUrl: string;
  apiUrl: string;
  apiKey: string;
  partnerId: string | undefined;
  tenantId: string | undefined;
};
