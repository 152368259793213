import { Label } from "./Label";
import { Checkbox, CheckboxProps } from "./Checkbox";

export interface CheckboxInputProps extends CheckboxProps {
  label?: string;
  additionalText?: string;
}

export const CheckboxInput = ({
  label: labelText,
  additionalText,
  className,
  ...props
}: CheckboxInputProps) => {
  return (
    <div className="items-top flex space-x-2">
      <Checkbox className={className} {...props} />
      <div className="grid gap-1.5 leading-none">
        {labelText && <Label htmlFor={props.id}>{labelText}</Label>}
        {additionalText && (
          <p className="text-sm text-muted-foreground">(additionalText)</p>
        )}
      </div>
    </div>
  );
};
