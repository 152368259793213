import { ComponentProps } from "react";
import {
  Select as BaseSelect,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/Base/Select";
import { Option } from "@/types/v2/UI";

interface SelectProps {
  onValueChange?: (value: string) => void;
  value: string | undefined;
  defaultValue?: string;
  placeholder?: string;
  options: Option[];
  selectContentProps?: ComponentProps<typeof SelectContent>;
  selectTriggerProps?: ComponentProps<typeof SelectTrigger>;
  selectItemProps?: ComponentProps<typeof SelectItem>;
  selectValueProps?: ComponentProps<typeof SelectValue>;
  disabled?: boolean;
}

export const Select = ({
  onValueChange,
  value,
  defaultValue,
  placeholder,
  options,
  selectContentProps,
  selectTriggerProps,
  selectItemProps,
  selectValueProps,
  disabled,
}: SelectProps) => {
  return (
    <BaseSelect
      onValueChange={onValueChange}
      value={value}
      defaultValue={defaultValue}
    >
      <SelectTrigger {...selectTriggerProps} disabled={disabled}>
        <SelectValue {...selectValueProps} placeholder={placeholder} />
      </SelectTrigger>
      <SelectContent {...selectContentProps}>
        {options.map((option, index) => (
          <SelectItem
            {...selectItemProps}
            key={`${option.value}-${index}`}
            value={option.value}
          >
            {option.label}
          </SelectItem>
        ))}
      </SelectContent>
    </BaseSelect>
  );
};
