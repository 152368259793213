import { ReactNode, useState } from "react";
import { Button, ButtonProps } from "@/components/Base/Button";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/Base/Dialog";
import {
  BuilderList,
  BuilderListProps,
} from "@/components/Automations/BuilderList";

interface BuilderDialogProps {
  title?: string;
  buttonTitle?: string | ReactNode;
  buttonSize?: ButtonProps["size"];
  onSubmit: (automation: any) => Promise<void>;
  builderListProps: BuilderListProps;
  disabled?: boolean;
}
export const BuilderDialog = ({
  title,
  buttonTitle,
  onSubmit,
  builderListProps,
  disabled,
  buttonSize = "default",
}: BuilderDialogProps) => {
  const [open, setOpen] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSelection = async () => {
    setIsSubmitting(true);
    try {
      if (onSubmit) {
        await onSubmit("");
      }
    } catch (error) {
      console.log(error);
    }
    setIsSubmitting(false);
    setOpen(false);
  };

  return (
    <Dialog onOpenChange={setOpen} open={open}>
      <DialogTrigger asChild>
        <Button
          type="button"
          variant={"ghost"}
          onClick={() => setOpen(true)}
          disabled={disabled}
          size={buttonSize}
        >
          {buttonTitle}
        </Button>
      </DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>{title}</DialogTitle>
        </DialogHeader>
        <BuilderList
          sections={builderListProps.sections.map((s) => ({
            ...s,
            buttons: s.buttons.map((b) => ({ ...b, disabled: isSubmitting })),
          }))}
          onSelection={() => handleSelection()}
        />
      </DialogContent>
    </Dialog>
  );
};
