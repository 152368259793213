import { useStore } from "@/stores/useStore";
import { DynamicOptions, Option } from "@/types/v2";
import { DEFAULT_COLORS } from "@/lib/const";
import { useCallback, useMemo } from "react";

export const useDynamicOptions = () => {
  const customColors = useStore((s) => s.shop?.cardConfig?.cardColors ?? []);
  const containers = useStore((s) => s.shop?.containers ?? []);
  const technicians = useStore((s) => s.employees ?? []);

  const colorOptions = useMemo((): Option[] => {
    return customColors
      .map(
        (c): Option => ({
          label:
            c.title === ""
              ? (DEFAULT_COLORS.find((dc) => dc.id === c.id)?.title ?? "")
              : c.title,
          value: c.color,
        })
      )
      .sort((a, b) => a.label.localeCompare(b.label));
  }, [customColors]);

  const containerOptions = useMemo((): Option[] => {
    return containers
      .map((c): Option => ({ label: c.displayName ?? c.id, value: c.id }))
      .sort((a, b) => a.label.localeCompare(b.label));
  }, [containers]);

  const technicianOptions = useMemo((): Option[] => {
    return technicians
      .map(
        (t): Option => ({ label: t.firstName + " " + t.lastName, value: t.id })
      )
      .sort((a, b) => a.label.localeCompare(b.label));
  }, [technicians]);

  const generateOptions = useCallback(
    (options: Option[] | undefined, type?: DynamicOptions | null) => {
      if (type === "colors") {
        return colorOptions;
      } else if (type === "containers") {
        return containerOptions;
      } else if (type === "technicians") {
        return technicianOptions;
      }
      return options;
    },
    [colorOptions, containerOptions, technicianOptions]
  );

  return { generateOptions };
};
