import { User } from "@/types/v2";
import { createContext, PropsWithChildren, useContext, useState } from "react";

type UsersContextType = {
  users: User[];
  setUsers: (users: User[]) => void;
};

const UsersContextInitialState: UsersContextType = {
  users: [],
  setUsers: () => {},
};

const UsersContext = createContext<UsersContextType>(UsersContextInitialState);

interface UsersProviderProps extends PropsWithChildren {
  initialUsers?: User[];
}

export const UsersProvider = ({
  initialUsers,
  children,
}: UsersProviderProps) => {
  const [users, setUsers] = useState<User[]>(
    initialUsers ?? UsersContextInitialState.users
  );
  return (
    <UsersContext.Provider value={{ users, setUsers }}>
      {children}
    </UsersContext.Provider>
  );
};

export const useUsersContext = () => {
  const context = useContext(UsersContext);
  if (!context) {
    console.warn("useUsersContext must be used within an UsersProvider");
  }
  return context;
};
