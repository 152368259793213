import { user } from "@/models/user";
import { useQueries, useQuery } from "react-query";

export const userQuery = (userId: string) => ({
  queryKey: ["users", userId],
  queryFn: () => user.getById(userId),
  refetchOnWindowFocus: false,
});

export const useUsersQueries = (userIds: string[]) => {
  return useQueries(
    userIds.map((userId) => ({
      queryKey: ["users", userId],
      queryFn: () => user.getById(userId),
    }))
  );
};

export const useUserQuery = (userId: string) => {
  return useQuery(userQuery(userId));
};
