import { BaseFirestore } from "./Firestore";
import { Card } from "./Card";
import { Integrators } from "./Integrator";
import { Option } from "./UI";

export const TriggerObjects = {
  card: "card",
} as const;

export type TriggerObject =
  (typeof TriggerObjects)[keyof typeof TriggerObjects];

type TriggerObjectLabelsType = {
  // eslint-disable-next-line no-unused-vars
  [key in TriggerObject]: string;
};

export const TriggerObjectLabels: TriggerObjectLabelsType = {
  [TriggerObjects.card]: "Card",
};

export const TriggerEventTypes = {
  onCreate: "onCreate",
  onUpdate: "onUpdate",
  onDelete: "onDelete",
} as const;

export type TriggerEventType =
  (typeof TriggerEventTypes)[keyof typeof TriggerEventTypes];

export const TriggerFieldChange = {
  changedTo: "changedTo",
  changedFrom: "changedFrom",
} as const;

export type TriggerFieldChange =
  (typeof TriggerFieldChange)[keyof typeof TriggerFieldChange];

export type TriggerFieldChangeLabelsType = {
  // eslint-disable-next-line no-unused-vars
  [key in TriggerFieldChange]: string;
};

export const TriggerFieldChangeLabels: TriggerFieldChangeLabelsType = {
  [TriggerFieldChange.changedTo]: "changed to",
  [TriggerFieldChange.changedFrom]: "changed from",
};

export type TriggerField = keyof Pick<
  Card,
  "currentContainer" | "color" | "technicianId"
>;

export const TriggerFieldLabels: {
  // eslint-disable-next-line no-unused-vars
  [key in TriggerField]: string;
} = {
  currentContainer: "Current Container",
  color: "Background Color",
  technicianId: "Technician",
};

export const TriggerConditions = {
  is: "is",
  isNot: "isNot",
} as const;

export type TriggerCondition =
  (typeof TriggerConditions)[keyof typeof TriggerConditions];

export type TriggerConditionLabelsType = {
  // eslint-disable-next-line no-unused-vars
  [key in TriggerCondition]: string;
};

export const TriggerConditionLabels: TriggerConditionLabelsType = {
  [TriggerConditions.is]: "is",
  [TriggerConditions.isNot]: "is not",
};

export const ActionObjects = {
  card: "card",
} as const;

export type ActionObject = (typeof ActionObjects)[keyof typeof ActionObjects];

type ActionObjectLabelsType = {
  // eslint-disable-next-line no-unused-vars
  [key in ActionObject]: string;
};

export const ActionObjectLabels: ActionObjectLabelsType = {
  [ActionObjects.card]: "Card",
};

export const ActionEventTypes = {
  update: "update",
  create: "create",
  reset: "reset",
} as const;

export type ActionEventType =
  (typeof ActionEventTypes)[keyof typeof ActionEventTypes];

export type ActionField = keyof Pick<
  Card,
  | "color"
  | "automationAction"
  | "automationActionExpireAt"
  | "automationActionStartAt"
  | "automationActionData"
>;

export const ActionFieldLabels: {
  // eslint-disable-next-line no-unused-vars
  [key in ActionField]: string;
} = {
  color: "Background Color",
  automationAction: "Automation Action",
  automationActionExpireAt: "Automation Action Expires At",
  automationActionStartAt: "Automation Action Starts At",
  automationActionData: "Automation Action Data",
};

export type Trigger = {
  templateId: string;
  triggerObject: TriggerObject;
  triggerEventType: TriggerEventType;
  triggerField: TriggerField | null;
  triggerFieldChange: TriggerFieldChange | null;
  triggerCondition: TriggerCondition | null;
  triggerValue: string | number | null;
};

export type Action = {
  templateId: string;
  actionObject: ActionObject;
  actionObjectIdentifier: "self" | "new" | string;
  actionEventType: ActionEventType;
  actionField: ActionField | null;
  actionValue: string | number | null;
};

export type Automation = BaseFirestore & {
  id: string;
  shopId: string;
  sortOrder: number;
  triggers: Trigger[];
  triggersConditional: "AND" | "OR";
  actions: Action[];
  name?: string;
  description?: string;
  enabled: boolean;
};

export type CreateAutomationDto = Pick<
  Automation,
  "triggers" | "triggersConditional" | "actions" | "name" | "description"
>;

export const Icons = {
  card: "card",
  dueDate: "dueDate",
  container: "container",
  employee: "employee",
  ...Integrators,
} as const;

export type BuilderButtonIcon = (typeof Icons)[keyof typeof Icons];

export const DynamicOptions = {
  colors: "colors",
  containers: "containers",
  technicians: "technicians",
  serviceAdvisors: "serviceAdvisors",
} as const;

export type DynamicOptions =
  (typeof DynamicOptions)[keyof typeof DynamicOptions];

export type BuilderTrigger = {
  id: string;
  triggerObject: TriggerObject;
  triggerEventType?: TriggerEventType;
  triggerEventTypeOptions?: TriggerEventType[];
  triggerField: TriggerField | null;
  triggerFieldOptions?: TriggerField[] | null;
  triggerFieldChange: TriggerFieldChange | null;
  triggerFieldChangeOptions?: TriggerFieldChange[];
  triggerCondition: TriggerCondition | null;
  triggerConditionOptions?: Option<TriggerCondition>[];
  triggerValue: string | null;
  triggerValueOptionsDynamic?: DynamicOptions | null;
  triggerValueOptions?: Option[];
  triggerPlaceholder?: string;
  buttonOptions: {
    text: string;
    selectedText?: string;
    icon: BuilderButtonIcon;
  };
  conditionalTriggers?: BuilderTrigger[];
};

export type BuilderAction = {
  id: string;
  required?: boolean;
  actionObject: ActionObject;
  actionEventType: ActionEventType;
  actionField: ActionField | null;
  actionFieldOptions?: Option<ActionField>[];
  actionValue: string | null;
  actionValueOptionsDynamic?: DynamicOptions | null;
  actionValueEditable: boolean;
  actionValueOptions?: Option[];
  actionPlaceholder?: string;
  buttonOptions: {
    text: string;
    selectedText?: string;
    icon: BuilderButtonIcon;
  };
  subsequentActions?: BuilderAction[];
};
