import { Message as MessageType, ReactionType } from "@/types/v2/Message";
import { Message } from "@/components/MessageBoard/Message";
import { firestoreToDateTime } from "@/lib/utils";
import { User } from "@/types/v2";
import { Fragment, useEffect, useMemo, useRef } from "react";
import { DateHeading } from "@/components/Common/DateHeading";
import { format, parse } from "date-fns";

export interface MessageThreadProps {
  messages: MessageType[];
  userId: string;
  users: User[];
  onReaction?: (messageId: string, reaction: ReactionType) => void;
  onEdit?: (messageId: string) => void;
  onDelete?: (messageId: string) => void;
}
export const MessageThread = ({
  messages,
  userId,
  users,
  onReaction,
  onEdit,
  onDelete,
}: MessageThreadProps) => {
  const messagesEndRef = useRef<HTMLDivElement | null>(null);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(scrollToBottom, [messages]);

  const sections = useMemo(() => {
    const sections: { [date: string]: MessageType[] } = {};
    messages.forEach((m) => {
      const dateTime = firestoreToDateTime(m.createdAt);
      const date = dateTime ? format(dateTime, "yyyy-MM-dd") : "";
      if (!sections[date]) {
        sections[date] = [];
      }
      sections[date].push(m);
    });
    return sections;
  }, [messages]);

  const onReactionHandler = (messageId: string, reaction: ReactionType) => {
    if (onReaction) {
      onReaction(messageId, reaction);
    }
  };

  const onEditHandler = (messageId: string) => {
    if (onEdit) {
      onEdit(messageId);
    }
  };

  const onDeleteHandler = (messageId: string) => {
    if (onDelete) {
      onDelete(messageId);
    }
  };

  return (
    <div className={"flex flex-col gap-4 overflow-y-auto"}>
      {Object.keys(sections).map((date) => (
        <Fragment key={date}>
          <DateHeading
            key={date}
            date={parse(date, "yyyy-MM-dd", new Date())}
          />
          {sections[date].map((m, index) => (
            <Message
              key={index}
              content={m.content}
              sender={users.find((u) => u.id === m.senderId)}
              isSender={m.senderId === userId}
              timestamp={firestoreToDateTime(m.createdAt)}
              reactions={m.reactions ?? []}
              onReactionClick={(reaction: ReactionType) =>
                onReactionHandler(m.id, reaction)
              }
              onEditClick={() => onEditHandler(m.id)}
              onDeleteClick={() => onDeleteHandler(m.id)}
            />
          ))}
        </Fragment>
      ))}

      <div ref={messagesEndRef} />
    </div>
  );
};
