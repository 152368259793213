import { Dashboard } from "@/components/Dashboard/Dashboard.tsx";
import { useTermsAccepted } from "@/hooks/useTermsAccepted";
import { TermsDialog } from "@/components/Dialogs/TermsDialog";
import { Loading } from "@/components/Page/Loading";

const ShopDashboardPage = () => {
  const { termsAccepted, termsLoading } = useTermsAccepted();
  if (termsLoading) {
    return (
      <div className="mx-auto flex flex-grow flex-col justify-center items-center h-96">
        <Loading />
      </div>
    );
  }
  return (
    <>
      {!termsAccepted && <TermsDialog open={true} setOpen={() => null} />}
      <Dashboard />
    </>
  );
};

export default ShopDashboardPage;
