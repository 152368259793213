import { AppVersion } from "@/components/Common/AppVersion";

export const Footer = () => {
  return (
    <footer className="text-xs flex flex-row justify-between px-2 h-4">
      <div>ShopHero Dashboard</div>
      <div className="flex-grow"></div>
      <AppVersion />
    </footer>
  );
};
