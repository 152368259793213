import { useStore } from "@/stores/useStore";
import { Feature } from "flagged";
import { CardMenu } from "./CardMenu";
import { CardTechSALabel } from "./CardTechSALabel";
import { cn } from "@/lib/utils";
import { DragHandleProps } from "./Card.DragHandle";
import { DebugInfo } from "./Card.DebugInfo";
import { useState } from "react";
import { Card, Integration } from "@/types/v2";
import { ClassNameValue } from "tailwind-merge";
import { createSubdomainUrl } from "@/lib/createSubdomainUrl";

interface CardHeaderProps extends DragHandleProps {
  cardId: string;
  cardData: Card;
  primaryLabel?: string;
  disabled?: boolean;
}

interface UnlinkedLabelProps extends DragHandleProps {
  primaryLabel: string | undefined;
}

const UnlinkedLabel = ({
  dndAttributes,
  dndListeners,
  primaryLabel,
}: UnlinkedLabelProps) => (
  <button
    className="text-left overflow-x-hidden h-auto whitespace-nowrap cursor-grab"
    {...dndAttributes}
    {...dndListeners}
  >
    <p>
      <span
        className={cn(
          "font-bold",
          primaryLabel ? getPrimaryTextSize(primaryLabel) : "text-2xl"
        )}
      >
        {primaryLabel}
      </span>
    </p>
  </button>
);

interface LinkedLabelProps {
  shopId: string;
  subdomain: string;
  cardId: string;
  primaryLabel: string | undefined;
  integration: Integration;
}

const getPrimaryTextSize = (label: string): ClassNameValue => {
  if (label.length > 9) {
    return "text-base";
  } else if (label.length > 8) {
    return "text-lg";
  } else if (label.length > 7) {
    return "text-xl";
  } else {
    return "text-2xl";
  }
};

const LinkedLabel = ({
  shopId,
  subdomain,
  cardId,
  primaryLabel,
  integration,
}: LinkedLabelProps) => (
  <a
    className={cn(
      "w-full overflow-x-hidden h-auto whitespace-nowrap font-bold",
      primaryLabel ? getPrimaryTextSize(primaryLabel) : "text-2xl"
    )}
    rel="noreferrer"
    target="_blank"
    href={
      integration.integration === "tekmetric"
        ? `${integration.linkUrl}/admin/shop/${shopId}/repair-orders/${cardId}/estimate`
        : integration.integration === "shopware"
          ? `${createSubdomainUrl(subdomain, integration.linkUrl)}/work_orders/${cardId}`
          : ""
    }
  >
    {primaryLabel}
  </a>
);

interface ManualLabelProps {
  primaryLabel: string | undefined;
  cardId: string;
  cardData: Card;
  disabled?: boolean;
}

const ManualLabel = ({
  primaryLabel,
  cardId,
  cardData,
  disabled,
}: ManualLabelProps) => (
  <div className="w-full relative">
    <h3
      className={cn(
        "w-full overflow-x-hidden h-auto whitespace-nowrap font-bold",
        primaryLabel ? getPrimaryTextSize(primaryLabel) : "text-2xl"
      )}
    >
      {primaryLabel}
    </h3>
    {!disabled && (
      <CardMenu cardId={cardId} cardData={cardData} disabled={disabled} />
    )}
  </div>
);

export const CardHeader = ({
  cardId,
  cardData,
  dndAttributes,
  dndListeners,
  primaryLabel,
  disabled,
}: CardHeaderProps) => {
  const shopId = useStore((s) => s.shop?.shopId);
  const subdomain = useStore((s) => s.shop?.integrationSubdomain);
  const integration = useStore((s) => s.integration);
  const [open, setOpen] = useState(false);

  return (
    <div className="px-1 min-h-4" onDoubleClick={() => setOpen(true)}>
      <DebugInfo cardData={cardData} open={open} setOpen={setOpen} />

      <Feature name="isManualCardOnlyEnabled">
        {(isEnabled) =>
          isEnabled ? (
            <ManualLabel
              primaryLabel={primaryLabel}
              cardData={cardData}
              cardId={cardId}
              disabled={disabled}
            />
          ) : (
            <Feature name="isManualCardsEnabled">
              {(manualEnabled) =>
                manualEnabled ? (
                  <div className="w-full relative ">
                    <h3
                      className={cn(
                        "text-2xl font-bold overflow-x-hidden h-auto whitespace-nowrap ",
                        cardData.source === "manual" ? "mr-5" : ""
                      )}
                    >
                      <Feature name="isCardPrimaryLinkEnabled">
                        {(isLinkEnabled) =>
                          isLinkEnabled &&
                          shopId &&
                          integration &&
                          cardData.source !== "manual" ? (
                            <LinkedLabel
                              cardId={cardId}
                              subdomain={subdomain ?? ""}
                              shopId={shopId}
                              primaryLabel={primaryLabel}
                              integration={integration}
                            />
                          ) : (
                            <UnlinkedLabel
                              dndAttributes={dndAttributes}
                              dndListeners={dndListeners}
                              primaryLabel={primaryLabel}
                            />
                          )
                        }
                      </Feature>
                    </h3>
                    <CardTechSALabel cardData={cardData} />
                    {(cardData.source === "manual" ||
                      cardData.automationAction) && (
                      <CardMenu
                        cardId={cardId}
                        cardData={cardData}
                        disabled={disabled}
                      />
                    )}
                  </div>
                ) : (
                  <div className="w-full overflow-x-hidden relative">
                    <Feature name="isCardPrimaryLinkEnabled">
                      {(isLinkEnabled) =>
                        isLinkEnabled &&
                        shopId &&
                        integration &&
                        cardData.source !== "manual" ? (
                          <LinkedLabel
                            cardId={cardId}
                            subdomain={subdomain ?? ""}
                            shopId={shopId}
                            primaryLabel={primaryLabel}
                            integration={integration}
                          />
                        ) : (
                          <UnlinkedLabel
                            dndAttributes={dndAttributes}
                            dndListeners={dndListeners}
                            primaryLabel={primaryLabel}
                          />
                        )
                      }
                    </Feature>
                    <CardTechSALabel cardData={cardData} />
                  </div>
                )
              }
            </Feature>
          )
        }
      </Feature>
    </div>
  );
};
