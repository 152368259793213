import { formatDate } from "@/lib/utils";

export interface DateHeadingProps {
  date: Date;
}

export const DateHeading = ({ date }: DateHeadingProps) => {
  return (
    <div
      className={
        "mx-auto flex-row justify-center items-center bg-primary text-white rounded-md p-1"
      }
    >
      <div className="mx-4 text-center font-bold">{formatDate(date, true)}</div>
    </div>
  );
};
