import { functions } from "@/services/functions";
import { Timer } from "@/types/v1";
import {
  AuthUser,
  EmployeeRole,
  Integrators,
  CustomerUpdate,
} from "@/types/v2";
import { httpsCallable } from "firebase/functions";

export const httpsCallables = {
  updateShopTimers: (shopId: string, timerId: string, timerData?: Timer) => {
    const callable = httpsCallable(functions, "updateShopTimer");
    if (timerData) {
      return callable({ shopId, timerId, timerData }).then(() =>
        console.log("Finished")
      );
    }
    return callable({ shopId, timerId }).then(() => console.log("Finished"));
  },
  syncWithSource: ({
    shopId,
    integrator,
    apiToken,
    apiUrl,
    fullSync,
    apiPartnerId,
    apiTenantId,
  }: {
    shopId: string;
    integrator: Integrators;
    apiToken?: string;
    apiUrl?: string;
    fullSync?: boolean;
    apiPartnerId?: string;
    apiTenantId?: string;
  }) => {
    const callable = httpsCallable(functions, "integrations-sync");
    return callable({
      shopId,
      integrator,
      apiToken,
      apiUrl,
      fullSync,
      apiPartnerId,
      apiTenantId,
    });
  },
  updateContainers: ({
    shopId,
    cardId,
    fromContainerIndex,
    fromContainerId,
    toContainerIndex,
    toContainerId,
  }: {
    shopId: string;
    cardId: string;
    fromContainerIndex: number;
    fromContainerId: string;
    toContainerIndex: number;
    toContainerId: string;
  }) => {
    const callable = httpsCallable(functions, "updateContainers");
    return callable({
      shopId,
      tagId: cardId,
      from: {
        index: fromContainerIndex,
        container: fromContainerId,
      },
      to: {
        index: toContainerIndex,
        container: toContainerId,
      },
    });
  },
  updateUserClaims: ({
    userId,
    userRole,
    userRoleEnabled,
    userShopId,
    disableUser,
  }: {
    userId: string;
    userRole?: EmployeeRole;
    userRoleEnabled?: boolean;
    userShopId?: string;
    disableUser?: boolean;
  }) => {
    const callable = httpsCallable(functions, "functionsV2-updateUserClaims");
    return callable({
      userId,
      userRole,
      userRoleEnabled,
      userShopId,
      disableUser,
    });
  },
  getAuthUser: ({ email }: { email: string }) => {
    const callable = httpsCallable<{ email: string }, AuthUser>(
      functions,
      "functionsV2-getAuthUser"
    );
    return callable({ email });
  },
  createAuthUser: async ({
    email,
    displayName,
  }: {
    email: string;
    displayName: string;
  }) => {
    const callable = httpsCallable<
      {
        email: string;
        displayName: string;
      },
      AuthUser
    >(functions, "functionsV2-createAuthUser");
    return callable({ email, displayName });
  },
  deactivateAuthUser: ({ email }: { email: string }) => {
    const callable = httpsCallable<{ email: string }, AuthUser>(
      functions,
      "functionsV2-deactivateAuthUser"
    );
    return callable({ email });
  },
  moveCard: ({
    cardId,
    shopId,
    toContainerId,
    toIndex,
  }: {
    cardId: string;
    shopId: string;
    toContainerId?: string;
    toIndex?: number;
  }) => {
    const callable = httpsCallable(functions, "cards-moveCard");
    return callable({ cardId, shopId, toContainerId, toIndex });
  },
  customerUpdateUpdate: ({
    shopId,
    customerUpdateId,
    customerUpdateData,
  }: {
    shopId: string;
    customerUpdateId: string;
    customerUpdateData: CustomerUpdate;
  }) => {
    const callable = httpsCallable(
      functions,
      "functionsV2-customerUpdateUpdate"
    );
    return callable({ shopId, customerUpdateId, customerUpdateData });
  },
  integratorStatus: (data: {
    shopId: string;
    integrator: Integrators;
    apiToken?: string;
    apiUrl?: string;
    apiPartnerId?: string;
    apiTenantId?: string;
  }) => {
    const callable = httpsCallable(functions, "integrations-status");
    return callable(data);
  },
  registerShop: (data: {
    email: string;
    shopName: string;
    website: string;
    phone: string;
    tenantId?: string;
    shopId?: string;
    integrator?: Integrators;
  }) => {
    const callable = httpsCallable(functions, "functionsV2-registerShop");
    return callable(data);
  },
};
