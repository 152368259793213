import { useIsAuthenticated } from "@/hooks/useIsAuthenticated";
import { useStore } from "@/stores/useStore";
import { shop as shopActions } from "@/models/shop";
import { EnableDisableSwitch } from "@/components/Common/EnableDisableSwitch";
import { useRoles } from "@/hooks/useRoles";
import { Loading } from "@/components/Page/Loading";
import { Forbidden } from "@/components/Page/Forbidden";
import { IntegratorsLabel } from "@/types/v2";
import { useCallback, useEffect, useState } from "react";
import { httpsCallables } from "@/models/httpsCallables";
import { Skeleton } from "@/components/Base/Skeleton";
import { cn } from "@/lib/utils";
import { Button } from "@/components/Base/Button";
import { RefreshCcwIcon } from "lucide-react";

const IntegrationsPage = () => {
  const { isSuperAdmin, isAdmin, isOffice, isInitialized } = useRoles();
  useIsAuthenticated();
  const shopData = useStore((s) => s.shop);
  const setIntegration = useStore((s) => s.setIntegration);
  const integration = useStore((s) => s.integration);

  const handleToggleDevKey = () => {
    if (!shopData) return;
    return shopActions
      .update(shopData.id, {
        integrationKey: shopData.integrationKey === "dev" ? "prod" : "dev",
      })
      .then(() => setIntegration());
  };

  const [integrationActive, setIntegrationActive] = useState(false);
  const [statusLoading, setStatusLoading] = useState(false);

  const checkStatus = useCallback(() => {
    setStatusLoading(true);
    httpsCallables
      .integratorStatus({
        shopId: shopData?.shopId ?? "",
        integrator: integration?.integration ?? "tekmetric",
        apiTenantId: integration?.tenantId,
        apiUrl: integration?.apiUrl,
        apiToken: integration?.apiKey,
        apiPartnerId: integration?.partnerId,
      })
      .then((res) => {
        setIntegrationActive(res.data as boolean);
      })
      .finally(() => setStatusLoading(false));
  }, [shopData, integration]);

  useEffect(() => {
    checkStatus();
  }, [shopData, integration]);

  if (!isInitialized) return <Loading />;
  if (isInitialized && !(isSuperAdmin || isAdmin || isOffice))
    return <Forbidden />;

  return (
    <div className="p-5 max-w-screen-md">
      <h1 style={{ fontSize: 24 }}>Integrations</h1>

      {shopData ? (
        <div>
          <div className="flex flex-row gap-8 items-center w-[800px]">
            {statusLoading ? (
              <Skeleton className={"w-6 h-6 rounded-full"} />
            ) : (
              <div
                className={cn(
                  "w-6 h-6 rounded-full",
                  integrationActive ? "bg-green" : "bg-red"
                )}
              />
            )}
            <div>
              Your current integration with{" "}
              {integration?.integration
                ? IntegratorsLabel[integration?.integration]
                : "UNKNOWN"}{" "}
              shop {shopData.shopId} is{" "}
              <span
                className={cn(
                  "font-bold",
                  statusLoading
                    ? "text-white"
                    : integrationActive
                      ? "text-green"
                      : "text-red"
                )}
              >
                {statusLoading
                  ? "LOADING"
                  : integrationActive
                    ? "ACTIVE"
                    : "INACTIVE"}
              </span>
            </div>
            <div className="flex-grow" />
            <Button variant={"accent"} onClick={checkStatus}>
              <RefreshCcwIcon className={"mr-4"} /> Refresh
            </Button>
          </div>
          {isSuperAdmin && (
            <div>
              <EnableDisableSwitch
                label={"Production Key"}
                enabled={
                  shopData?.integrationKey
                    ? shopData.integrationKey === "prod"
                    : true
                }
                onChange={() => handleToggleDevKey()}
              />
            </div>
          )}
        </div>
      ) : (
        <div>Loading...</div>
      )}
    </div>
  );
};

export default IntegrationsPage;
