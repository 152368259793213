import { useState } from "react";
import shdbLogo from "../../assets/ShdbLogo.png";
import { Form, Formik } from "formik";
import { FormikInput } from "@/components/Common/form/FormikInput";
import { FormikSelect } from "@/components/Common/form/FomikSelect";
import { Integrators, IntegratorsLabel } from "@/types/v2";
import { Button } from "@/components/Base/Button";
import * as yup from "yup";
import { useStore } from "@/stores/useStore";
import { useNavigate } from "react-router-dom";
import { ShopIdHelpDialog } from "@/components/Dialogs/ShopId.HelpDialog";
import { TenantIdHelpDialog } from "@/components/Dialogs/TenantId.HelpDialog";

const validationSchema = yup.object({
  shopName: yup.string().required("Shop name is required"),
  phone: yup.string().required("Phone number is required"),
  website: yup.string(),
  email: yup
    .string()
    .email("Enter a valid email")
    .required("Email is required"),
  integrator: yup
    .string()
    .required("Integrator is required")
    .oneOf([...Object.keys(Integrators)], "Integrator is required"),
  shopId: yup.string().when("integrator", {
    is: (integrator: Integrators) => integrator !== "manual",
    then: (schema) => schema.required("Shop ID is Required"),
    otherwise: (schema) => schema.notRequired(),
  }),
  tenantId: yup.string().when("integrator", {
    is: (integrator: Integrators) => integrator === "shopware",
    then: (schema) => schema.required("Tenant ID is Required"),
    otherwise: (schema) => schema.notRequired(),
  }),
  password: yup
    .string()
    .min(8, "Password should be of minimum 8 characters length")
    .required("Password is required"),
  confirmPassword: yup
    .string()
    .required("Required")
    .oneOf([yup.ref("password"), ""], "Passwords must match"),
});

const SignupPage = () => {
  const navigate = useNavigate();
  const signup = useStore((s) => s.signup);
  const [shopIdHelpOpen, setShopIdHelpOpen] = useState(false);
  const [tenantIdHelpOpen, setTenantIdHelpOpen] = useState(false);

  const handleShopIdHelpClick = () => {
    setShopIdHelpOpen(true);
  };
  const handleTenantIdHelpClick = () => {
    setTenantIdHelpOpen(true);
  };

  const shopIdIntegrations = [
    Integrators.shopware,
    Integrators.tekmetric,
  ] as string[];
  const initialValues: yup.InferType<typeof validationSchema> = {
    shopName: "",
    shopId: "",
    email: "",
    website: "",
    phone: "",
    password: "",
    confirmPassword: "",
    integrator: "",
    tenantId: "",
  };

  return (
    <>
      <div className="min-h-full flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
        <div className="max-w-md w-full space-y-8 border-white border-2 rounded-xl p-8 bg-secondary/10">
          <div>
            <img
              className="mx-auto h-12 w-auto"
              src={shdbLogo}
              alt="Shop Hero Logo"
            />
            <h2 className="mt-6 text-center text-3xl font-extrabold text-primary-foreground">
              Create your account
            </h2>
          </div>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={(values, { setSubmitting }) => {
              setSubmitting(true);
              return signup({
                email: values.email,
                password: values.password,
                displayName: values.shopName,
                phone: values.phone,
                integrator: values.integrator as Integrators,
                shopName: values.shopName,
                shopId: values.shopId,
                tenantId:
                  values.integrator === "shopware"
                    ? values.tenantId
                    : undefined,
                website: values.website ?? "",
              })
                .then(() => {
                  navigate("/");
                  window.location.reload();
                })
                .finally(() => setSubmitting(false));
            }}
          >
            {({ values, isSubmitting, isValid }) => (
              <Form className={"flex flex-col gap-2"}>
                <FormikInput
                  name={"shopName"}
                  id={"shopName"}
                  type={"text"}
                  autoComplete={"name"}
                  label={"Shop Name"}
                  labelSrOnly
                  placeholder={"Shop Name"}
                />
                <FormikInput
                  name={"email"}
                  id={"email"}
                  type={"email"}
                  autoComplete={"email"}
                  label={"Email"}
                  labelSrOnly
                  placeholder={"Email"}
                />
                <FormikSelect
                  label={"Integrator"}
                  labelSrOnly
                  name={"integrator"}
                  id={"integrator"}
                  placeholder={"Select Integrator"}
                  options={Object.keys(Integrators).map((key) => ({
                    value: Integrators[key as Integrators],
                    label: IntegratorsLabel[Integrators[key as Integrators]],
                  }))}
                />
                {values.integrator === "shopware" && (
                  <FormikInput
                    name={"tenantId"}
                    id={"tenantId"}
                    type={"text"}
                    label={"Tenant ID"}
                    labelSrOnly
                    placeholder={"Tenant ID"}
                  />
                )}
                {shopIdIntegrations.includes(values.integrator) && (
                  <FormikInput
                    name={"shopId"}
                    id={"shopId"}
                    type={"text"}
                    label={"Shop ID"}
                    labelSrOnly
                    placeholder={"Shop ID"}
                  />
                )}
                <FormikInput
                  name={"website"}
                  id={"website"}
                  type={"text"}
                  label={"Website"}
                  labelSrOnly
                  placeholder={"Website"}
                />
                <FormikInput
                  name={"phone"}
                  id={"phone"}
                  type={"text"}
                  label={"Phone"}
                  labelSrOnly
                  placeholder={"Phone"}
                />
                <FormikInput
                  name={"password"}
                  id={"password"}
                  type={"password"}
                  label={"Password"}
                  labelSrOnly
                  placeholder={"Password"}
                />
                <FormikInput
                  name={"confirmPassword"}
                  id={"confirmPassword"}
                  type={"password"}
                  label={"Confirm Password"}
                  labelSrOnly
                  placeholder={"Confirm Password"}
                />
                <div className={"self-end flex flex-col justify-end gap-2"}>
                  {shopIdIntegrations.includes(values.integrator) && (
                    <Button
                      onClick={handleShopIdHelpClick}
                      variant="outline"
                      type="button"
                    >
                      Need help finding your shop Id?
                    </Button>
                  )}
                  {values.integrator === "shopware" && (
                    <Button
                      onClick={handleTenantIdHelpClick}
                      variant="outline"
                      type="button"
                    >
                      Need help finding your tenant Id?
                    </Button>
                  )}
                </div>
                <Button
                  type="submit"
                  variant="accent"
                  disabled={isSubmitting || !isValid}
                >
                  Create
                </Button>
                <ShopIdHelpDialog
                  open={shopIdHelpOpen}
                  setOpen={setShopIdHelpOpen}
                  integrator={values.integrator as Integrators}
                />
                <TenantIdHelpDialog
                  open={tenantIdHelpOpen}
                  setOpen={setTenantIdHelpOpen}
                  integrator={values.integrator as Integrators}
                />
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </>
  );
};

export default SignupPage;
