import { FormEvent, useEffect, useState } from "react";
import { EditSaveButtons } from "./EditSaveButtons";
import { cn } from "@/lib/utils";
import { ClassNameValue } from "tailwind-merge";
import {
  TimePicker,
  TimePickerProps,
  TimePickerSegment,
  TimePickerSeparator,
} from "@/components/Base/TimePicker";
import { format } from "date-fns";

interface EditSaveInputProps
  extends Omit<TimePickerProps, "children" | "onChange"> {
  value: Date;
  onSave: (arg: Date | undefined) => void;
  containerClassName?: ClassNameValue;
  disabled?: boolean;
}

export const EditSaveTime = ({
  value,
  onSave,
  containerClassName,
  disabled,
  ...props
}: EditSaveInputProps) => {
  const [timeState, setTimeState] = useState<Date | undefined>(new Date());
  const [isEditing, setIsEditing] = useState(false);

  useEffect(() => {
    if (value) {
      setTimeState(value);
    }
  }, [value]);

  const handleChange = (
    event: FormEvent<HTMLDivElement> | Date | undefined
  ) => {
    if (event instanceof Date) {
      onSave(event);
    }
  };

  const handleEdit = () => setIsEditing(true);
  const handleDiscard = () => {
    setIsEditing(false);
    setTimeState(value);
  };

  const handleSave = () => {
    onSave(value);
    setIsEditing(false);
  };

  return (
    <div
      className={cn(
        "flex flex-row flex-grow justify-between align-middle",
        containerClassName
      )}
    >
      {isEditing ? (
        <TimePicker value={timeState} {...props} onChange={handleChange}>
          <TimePickerSegment segment={"hours"} />
          <TimePickerSeparator>:</TimePickerSeparator>
          <TimePickerSegment segment={"minutes"} />
          {props.options?.hour12 && <TimePickerSegment segment={"am/pm"} />}
        </TimePicker>
      ) : (
        <div className="flex h-10 w-full rounded-md px-3 py-2 text-sm text-primary-foreground items-center">
          {timeState
            ? format(timeState, props.options?.hour12 ? "h:mm b" : "HH:mm")
            : "N/A"}
        </div>
      )}
      <EditSaveButtons
        containerClassName={cn("w-48 justify-end", disabled && "hidden")}
        isEditing={isEditing}
        handleDiscard={handleDiscard}
        handleEdit={handleEdit}
        handleSave={handleSave}
      />
    </div>
  );
};
