import { Avatar, AvatarProps } from "@/components/Common/Avatar";
import { User } from "@/types/v2";
import { getUsersInitials } from "@/lib/users";

interface UserAvatarProps extends AvatarProps {
  user?: User;
}
export const UserAvatar = ({ user, ...props }: UserAvatarProps) => {
  return (
    <Avatar
      src={user?.profileImage}
      fallback={getUsersInitials(user?.displayName)}
      bgColor={user?.profileColor}
      {...props}
    />
  );
};
