import { useDroppable } from "@dnd-kit/core";
import {
  SortableContext,
  horizontalListSortingStrategy,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { Tooltip, TooltipContent, TooltipTrigger } from "../../../Base/Tooltip";
import { TagIcon, UserIcon } from "lucide-react";
import { useStore } from "@/stores/useStore";
import { formatLabels, formatTechnicianNames } from "@/lib/cards";
import { Container as ContainerType } from "@/types/v2";
import { Card } from "@/components/Dashboard/components/Card/Card";
import { useRoles } from "@/hooks/useRoles";
import { useFeature } from "flagged";
import TimeDisplay from "@/components/Common/TimeDisplay";
import { useCallback, useEffect, useMemo, useState } from "react";
import { firestoreToDateTime } from "@/lib/utils";
import { format, parse, setHours } from "date-fns";
import { TimeInContainerMetric } from "@/types/v2/TimeInContainer";
import DeltaDisplay, {
  useDeltaDisplayMode,
} from "@/components/Common/DeltaDisplay";
import { WorkingDays } from "@/types/v2/ShopConfig";
import {
  DEFAULT_WORKING_HOURS_END,
  DEFAULT_WORKING_HOURS_START,
} from "@/lib/const";

interface ContainerProps extends ContainerType {
  direction: "row" | "column";
  gridArea: string;
  ticker: number;
  draggingId?: string;
}
export const Container = (props: ContainerProps) => {
  const { id, tags: items } = props;
  const { isViewOnly } = useRoles();
  const employees = useStore((s) => s.employees);
  const metrics = useStore((s) => s.timeInContainerMetrics);
  const shop = useStore((s) => s.shop);
  const technicianLinkingEnabled = useFeature("isTechnicianLinkEnabled");
  const labelLinkingEnabled = useFeature("isROLabelLinkEnabled");
  const todayMetrics = metrics
    .find((metric) => metric.id === format(new Date(), "yyyy-MM-dd"))
    ?.timeInContainer.find((met) => met.containerId === id);
  const [averageTimeInContainer, setAverageTimeInContainer] = useState(0);

  const { displayMode: deltaDisplayMode } = useDeltaDisplayMode();
  const currentMetrics = useMemo(
    () =>
      metrics
        .filter((metric) => metric.id === format(new Date(), "yyyy-MM-dd"))
        .map(
          (metric): TimeInContainerMetric => ({
            ...metric,
            timeInContainer: metric.timeInContainer.filter(
              (met) => met.containerId === id
            ),
          })
        ),
    [metrics, id]
  );
  const weekMetrics = useMemo(
    () =>
      metrics
        .filter((metric) => metric.id !== format(new Date(), "yyyy-MM-dd"))
        .map(
          (metric): TimeInContainerMetric => ({
            ...metric,
            timeInContainer: metric.timeInContainer.filter(
              (met) => met.containerId === id
            ),
          })
        ),
    [metrics, id]
  );
  const [weekAverage, setWeekAverage] = useState(0);

  const calculateTimeInContainer = (
    metric?: TimeInContainerMetric,
    cards?: string[]
  ) => {
    // We are going to default to Monday start time if it exists
    const dayStartTime = parseInt(
      (
        shop?.shopConfig?.workingHours[WorkingDays.Monday].startTime ??
        DEFAULT_WORKING_HOURS_START
      ).split(":")[0],
      10
    );
    const dayEndTime = parseInt(
      (
        shop?.shopConfig?.workingHours[WorkingDays.Monday].endTime ??
        DEFAULT_WORKING_HOURS_END
      ).split(":")[0],
      10
    );

    const dayStart = setHours(
      metric
        ? parse(metric.id, "yyyy-MM-dd", new Date())
        : new Date().setHours(0, 0, 0, 0),
      dayStartTime
    );
    const workDayEnd = setHours(
      metric ? parse(metric.id, "yyyy-MM-dd", new Date()) : new Date(),
      dayEndTime
    );
    const now = new Date();
    const timerEnd = workDayEnd > now ? now : workDayEnd;

    const timers =
      metric?.timeInContainer.flatMap((m) =>
        m.cardHistory.map((history) => {
          const enteredAt = firestoreToDateTime(history.enteredAt) as Date;
          const timerEnteredAt = enteredAt > dayStart ? enteredAt : dayStart;
          const exitedAt = firestoreToDateTime(history.exitedAt);
          const timerExitedAt = exitedAt
            ? exitedAt > timerEnd
              ? timerEnd
              : exitedAt
            : timerEnd;

          return {
            ...history,
            enteredAt: timerEnteredAt,
            exitedAt: timerExitedAt,
          };
        })
      ) ?? [];

    // These are cards that were in the container when today started
    const holdOverCards = (
      cards?.filter((card) => !timers.map((t) => t.cardId).includes(card)) ?? []
    ).map((card) => ({
      cardId: card,
      enteredAt: dayStart,
      exitedAt: timerEnd,
    }));

    const durations = [...timers, ...holdOverCards].map(
      (timer) =>
        (timer.exitedAt?.getTime() ?? timer.enteredAt.getTime()) -
        (timer.enteredAt.getTime() ?? timer.enteredAt.getTime())
    );
    const total = durations.reduce((acc, curr) => acc + curr, 0) / 1000;
    return total / durations.length;
  };

  const getWeeklyTime = useCallback(() => {
    const averages = weekMetrics.map((metric) => {
      if (!metric) return 0;
      const time = calculateTimeInContainer(metric);
      return isNaN(time) ? 0 : time;
    });

    setWeekAverage(
      averages.reduce((acc, curr) => acc + curr, 0) /
        Math.max(averages.length, 1)
    );
  }, [weekMetrics]);

  const getCurrentTime = useCallback(() => {
    const averages = currentMetrics.map((metric) => {
      if (!metric) return 0;
      const time = calculateTimeInContainer(metric, items);

      return isNaN(time) ? 0 : time;
    });
    // Unique case when there is no metric yet
    if (averages.length === 0 && items.length > 0) {
      const time = calculateTimeInContainer(undefined, items);
      setAverageTimeInContainer(time);
      return;
    }
    setAverageTimeInContainer(
      averages.reduce((acc, curr) => acc + curr, 0) /
        Math.max(averages.length, 1)
    );
  }, [currentMetrics, items]);

  useEffect(() => {
    const interval = setInterval(() => {
      getWeeklyTime();
      getCurrentTime();
    }, 1000);

    return () => clearInterval(interval);
  }, [todayMetrics, items, getWeeklyTime, getCurrentTime]);

  const { setNodeRef } = useDroppable({
    id,
  });

  return (
    <div className="relative">
      <div className="sticky top-0 flex bg-containerGray z-10 flex-row w-full justify-start p-1">
        <p className="flex-grow">{props.displayName}</p>
        {(averageTimeInContainer > 0 || weekAverage > 0) && (
          <div className={"flex flex-row gap-1"}>
            <div className={"flex flex-col"}>
              <Tooltip>
                <TooltipTrigger>
                  <TimeDisplay
                    size={"xs"}
                    timeInSeconds={Math.max(averageTimeInContainer, 0)}
                  />
                </TooltipTrigger>
                <TooltipContent>Average time in container today</TooltipContent>
              </Tooltip>

              <Tooltip>
                <TooltipTrigger>
                  <DeltaDisplay
                    delta={Math.max(
                      Math.round((averageTimeInContainer - weekAverage) / 60),
                      0
                    )}
                    units={"m"}
                    size={"xs"}
                  >
                    <TimeDisplay
                      size={"xs"}
                      timeInSeconds={weekAverage}
                      disabled
                    />
                  </DeltaDisplay>
                </TooltipTrigger>
                <TooltipContent>
                  {deltaDisplayMode === 0
                    ? "Average time in container the past week"
                    : "Change in average time compared to the past week"}
                </TooltipContent>
              </Tooltip>
            </div>
          </div>
        )}
        <div>
          {props.linkedSourceLabel && labelLinkingEnabled && (
            <Tooltip>
              <TooltipTrigger>
                <TagIcon className="w-4 h-4" />
              </TooltipTrigger>
              <TooltipContent>
                {`Linked to ${formatLabels(props.linkedSourceLabel)}`}
              </TooltipContent>
            </Tooltip>
          )}

          {props.linkedTechnicianId &&
            employees &&
            technicianLinkingEnabled && (
              <Tooltip>
                <TooltipTrigger>
                  <UserIcon className="w-4 h-4" />
                </TooltipTrigger>
                <TooltipContent>
                  {`Linked to ${formatTechnicianNames(props.linkedTechnicianId, employees)}`}
                </TooltipContent>
              </Tooltip>
            )}
        </div>
      </div>

      <SortableContext
        disabled={isViewOnly}
        id={id}
        items={items}
        strategy={
          props.direction === "row"
            ? horizontalListSortingStrategy
            : verticalListSortingStrategy
        }
      >
        <div ref={setNodeRef} className="flex overflow-auto flex-wrap flex-row">
          {props.tags.map((tag) => {
            return (
              <Card
                key={tag}
                cardId={tag}
                ticker={props.ticker}
                tentative={tag === props.draggingId}
              />
            );
          })}
        </div>
      </SortableContext>
    </div>
  );
};
