import { AutomationsConfiguration } from "@/components/Automations/AutomationsConfiguration";

export const AutomationsPage = () => {
  return (
    <div className="p-5 max-w-screen-xl flex flex-col flex-start w-full text-justify mx-auto mb-20 overflow-hidden h-full">
      <h1 className="text-3xl font-bold">Automations</h1>
      <AutomationsConfiguration />
    </div>
  );
};
