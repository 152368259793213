import { useField } from "formik";
import { Input, InputProps } from "@/components/Base/Input.tsx";
import { Label } from "@/components/Base/Label.tsx";
import { Error } from "@/components/Base/Error.tsx";
import { cn } from "@/lib/utils";

interface FormikInputProps extends InputProps {
  name: string;
  type: string;
  placeholder?: string;
  label?: string;
  labelSrOnly?: boolean;
}

export const FormikInput = ({
  label,
  labelSrOnly,
  className,
  ...props
}: FormikInputProps) => {
  const [field, meta] = useField(props);
  return (
    <div>
      {label && (
        <Label htmlFor={props.id} className={cn(labelSrOnly && "sr-only")}>
          {label}
        </Label>
      )}
      <Input {...field} {...props} className={cn("text-primary", className)} />
      {meta.touched && meta.error ? (
        <Error error={meta.error} className="mt-1" />
      ) : null}
    </div>
  );
};
