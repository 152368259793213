import { Button } from "@/components/Base/Button";
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "@/components/Base/Dialog";
import { Integrators, IntegratorsLabel } from "@/types/v2";
import { DialogDescription } from "@radix-ui/react-dialog";

interface TenantIdHelpDialogProps {
  open: boolean;
  setOpen: (arg: boolean) => void;
  integrator: Integrators;
}
export const TenantIdHelpDialog = ({
  open,
  setOpen,
  integrator,
}: TenantIdHelpDialogProps) => {
  const helpText = (integrator: Integrators) => {
    switch (integrator) {
      case "shopware":
        return "Your Tenant ID can be found by going to your Shop-Ware shop page, clicking on the settings button on the bottom left of your screen and navigating to Account Settings. Your Tenant ID will be under API Connections.";
      default:
        return "We don't seem to have help ready for your integration, or your integration does not require a Tenant ID. Contact customer support for further assistance.";
    }
  };

  return (
    <Dialog onOpenChange={setOpen} open={open}>
      <DialogContent className="max-w-screen-md">
        <DialogHeader>
          <DialogTitle>
            Need Help Finding Your {IntegratorsLabel[integrator]} Tenant ID?
          </DialogTitle>
        </DialogHeader>
        <DialogDescription>{helpText(integrator)}</DialogDescription>
        <DialogFooter>
          <Button
            className="mt-5"
            variant={"secondary"}
            onClick={() => setOpen(false)}
          >
            Ok
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};
