import { Sheet } from "@/components/Common/Sheet";
import { BellIcon } from "lucide-react";
import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from "@/components/Base/Tabs";
import { useStore } from "@/stores/useStore";
import { Separator } from "@/components/Base/Separator";
import { ActivityFeed } from "@/components/ActivityFeed/ActivityFeed";
import { useEffect, useMemo, useState } from "react";
import { ConversationRepository } from "@/models/conversation";
import { Spinner } from "@/components/Common/Spinner";
import { MessageBoard } from "@/components/MessageBoard/MessageBoard";
import { UsersProvider } from "@/providers/Users.provider";

export const NotificationSheet = () => {
  const { shop, conversations, conversationsLoaded, shopId, user } = useStore();
  const [conversationId, setConversationId] = useState<string>("");

  const conversation = useMemo(
    () => conversations.find((c) => c.id === conversationId),
    [conversations, conversationId]
  );

  useEffect(() => {
    if (shopId && conversationsLoaded && conversations.length === 0) {
      ConversationRepository.create(shopId, {
        type: "1:everyone",
        name: null,
        participants: [],
      });
    } else {
      setConversationId(
        conversations.find((c) => c.type === "1:everyone")?.id ?? ""
      );
    }
  }, [conversationsLoaded, conversations, shopId]);

  if (!conversation || !user || !shopId) {
    return <Spinner />;
  }

  return (
    <UsersProvider>
      <Sheet
        side={"right"}
        asChild={true}
        trigger={<BellIcon className={"self-center"} />}
        contentClassName={
          "p-0 border-0 w-[600px] sm:max-w-[600px] overflow-hidden flex flex-col gap-0 h-screen"
        }
        title={shop?.name + "'s HQ"}
        headerClassName={"bg-accent"}
        titleClassName={
          "text-4xl font-bold text-white h-16 p-4 flex flex-row justify-between items-center"
        }
      >
        <Tabs
          defaultValue="activityFeed"
          className={
            "w-full h-full flex-grow-0 overflow-hidden flex flex-col gap-0"
          }
        >
          <TabsList className="flex flex-row gap-2 space-y-0 justify-between h-16">
            <TabsTrigger
              className={
                "w-full h-full text-lg data-[state=active]:bg-transparent data-[state=active]:text-accent data-[state=active]:shadow-none"
              }
              value="activityFeed"
            >
              Activity Feed
            </TabsTrigger>
            <Separator orientation="vertical" className="bg-primary h-14" />
            <TabsTrigger
              className={
                "w-full h-full text-lg data-[state=active]:bg-transparent data-[state=active]:text-accent data-[state=active]:shadow-none"
              }
              value="messageBoard"
            >
              Message Board
            </TabsTrigger>
          </TabsList>
          <div className="flex flex-grow-0 overflow-hidden">
            <TabsContent
              value="activityFeed"
              className="flex flex-grow-0 overflow-auto"
            >
              <ActivityFeed />
            </TabsContent>
            <TabsContent
              value="messageBoard"
              className="flex flex-grow-0 overflow-hidden"
            >
              <MessageBoard
                conversation={conversation}
                shopId={shopId}
                className={"w-[600px]"}
              />
            </TabsContent>
          </div>
        </Tabs>
      </Sheet>
    </UsersProvider>
  );
};
