import { BaseFirestore } from "./Firestore";

export type MessageType = "text";

export const ReactionType = {
  like: "like",
  dislike: "dislike",
  agree: "agree",
  question: "question",
  happy: "happy",
} as const;

export type ReactionType = (typeof ReactionType)[keyof typeof ReactionType];

export type Reaction = {
  userId: string;
  type: ReactionType;
};

export type CreateMessageDto = {
  type: MessageType;
  content: string;
  senderId: string;
};

export type PatchMessageDto = Partial<Message>;

export type Message = BaseFirestore &
  CreateMessageDto & {
    id: string;
    readBy: string[];
    shopId: string;
    conversationId: string;
    reactions: Reaction[];
  };
