import { Outlet, useParams } from "react-router-dom";
import { MessageBoardRootPage } from "@/pages/(shop)/messageBoard/MessageBoardRoot.page";

export const MessageBoardLayoutPage = () => {
  const { conversationId } = useParams();

  if (!conversationId) {
    return <MessageBoardRootPage />;
  }

  return <Outlet />;
};
