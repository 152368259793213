import * as React from "react";
import { cva, type VariantProps } from "class-variance-authority";
import { cn } from "@/lib/utils";

const descriptionVariants = cva(
  "text-sm font-normal leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
);

const Description = React.forwardRef<
  HTMLParagraphElement,
  React.ComponentPropsWithoutRef<"p"> & VariantProps<typeof descriptionVariants>
>(({ className, ...props }, ref) => (
  <p ref={ref} className={cn(descriptionVariants(), className)} {...props} />
));
Description.displayName = "Description";

export { Description };
