import { Timestamp } from "firebase/firestore";

/**
 * Extracts the common items between two arrays of strings.
 *
 * @param {string[]} array1 - The first array of strings.
 * @param {string[]} array2 - The second array of strings.
 * @return {string[]} An array containing the items that are common in both array1 and array2.
 */
export function extractCommonItems(array1: string[], array2: string[]) {
  // Use the filter method to keep only the items that are common in both arrays
  return array1.filter((item) => array2.includes(item));
}

/**
 * Sums the values of a specified property in an array of objects.
 *
 * @param {Object[]} arr - The array of objects to be processed.
 * @param {string} property - The property whose values will be summed.
 * @return {number} The total sum of the specified property values.
 */
export function sumProperty(
  arr: any[], // eslint-disable-line @typescript-eslint/no-explicit-any
  property: string
) {
  return arr.reduce((sum, obj) => sum + obj[property], 0);
}

/**
 * Converts a Firestore `Timestamp` to a JavaScript `Date` object.
 * If the given value is already a `Date` object, it is returned as is.
 * If the value is undefined, it returns undefined.
 *
 * @param {Timestamp | Date | undefined} firestoreTime - The Firestore timestamp to convert.
 * @return {Date | undefined} - The corresponding JavaScript `Date` object or undefined.
 */
export function firestoreToDateTime(
  firestoreTime: Timestamp | Date | undefined
) {
  if (!firestoreTime) return undefined;
  if (firestoreTime instanceof Date) return firestoreTime;
  return firestoreTime?.toDate();
}

/**
 * Adds a value to an array if it is not already present.
 * The value will be added at the end of the array.
 *
 * @param {T} value - The value to add to the array.
 * @param {T[]} array - The array to which the value should be added.
 * @return {T[]} A new array with the value added if it was not already present.
 */
export function arrayUnion<T>(value: T, array: T[]) {
  return [...array.filter((item) => item !== value), value];
}

/**
 * Removes all instances of the specified value from the array.
 *
 * @param {T} value - The value to be removed from the array.
 * @param {T[]} array - The array from which the value will be removed.
 * @return {T[]} A new array with the specified value removed.
 */
export function arrayRemove<T>(value: T, array: T[]) {
  return [...array.filter((item) => item !== value)];
}

/**
 * Pauses the execution for a specified number of milliseconds.
 *
 * @param {number} ms - The number of milliseconds to wait.
 * @return {Promise<void>} A promise that resolves after the specified duration.
 */
export function wait(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

/**
 * Calculates the difference between two Date objects in seconds.
 *
 * @param {Date} date1 - The first date object.
 * @param {Date} date2 - The second date object.
 * @return {number} The time difference between the two dates in seconds.
 */
export function getTimeDifferenceInSeconds(date1: Date, date2: Date): number {
  const timeDifference = Math.abs(date2.getTime() - date1.getTime());
  return Math.floor(timeDifference / 1000);
}
