import { auth, authActions } from "@/services/auth";
import {
  sendEmailVerification,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
} from "firebase/auth";
import { StateCreator } from "zustand";
import { UserSlice } from "./user.slice";
import { sentry } from "@/services/sentry";
import { AuthState, AuthUser } from "@/types/v1";
import { AuthCustomClaims, Integrators, User } from "@/types/v2";
import { toast } from "@/hooks/useToast";
import { user } from "@/models/user";
import { httpsCallables } from "@/models/httpsCallables";
import { config } from "@/config";

export interface AuthSlice {
  initialized: boolean;
  authUser: AuthUser | undefined;
  authState: AuthState;
  authError: string | undefined;
  login: (email: string, password: string) => Promise<void>;
  logout: () => void;
  sendVerificationEmail: () => void;
  signup: (obj: {
    email: string;
    password: string;
    displayName: string;
    website: string;
    phone: string;
    shopName: string;
    tenantId?: string;
    shopId?: string;
    integrator: Integrators;
  }) => Promise<void>;
  resetPassword: (email: string) => void;
  authListener: () => void;
  authClaims: AuthCustomClaims | undefined;
}

const initialSlice: Pick<
  AuthSlice,
  "initialized" | "authState" | "authError" | "authUser" | "authClaims"
> = {
  initialized: false,
  authUser: undefined,
  authState: "unauthenticated",
  authError: undefined,
  authClaims: undefined,
};

console.log(auth.currentUser);
export const createAuthSlice: StateCreator<
  AuthSlice & UserSlice,
  [],
  [],
  AuthSlice
> = (set, get) => ({
  ...initialSlice,
  authListener: () => {
    auth.onAuthStateChanged((user) => {
      console.log("Auth state changed:", user);
      if (user) {
        authActions.getUserClaims().then((claims) =>
          set({
            authClaims: {
              user: claims?.user ?? false,
              superAdmin: claims?.superAdmin ?? false,
              shops: claims?.shops ?? {},
            },
          })
        );
        get()
          .subscribeUser(user)
          .then(() => {
            set({
              authState: user.emailVerified ? "authenticated" : "unverified",
              authUser: {
                uid: user.uid,
                email: user.email,
                emailVerified: user.emailVerified,
              },
              initialized: true,
            });
          });
      } else {
        set({
          ...initialSlice,
          initialized: true,
          user: undefined,
          allowedShops: [],
          authClaims: undefined,
        });
      }
    });
  },
  login: async (email: string, password: string) => {
    set({ authState: "signingIn", authError: undefined });
    await signInWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        const user = userCredential.user;
        console.log("User logged in:", user.email);
        sentry.setUser({
          email: userCredential.user.email ?? "",
          id: userCredential.user.uid,
        });
        set({
          authState: userCredential.user.emailVerified
            ? "authenticated"
            : "unverified",
          authUser: {
            uid: userCredential.user.uid,
            email: userCredential.user.email ?? email,
            emailVerified: userCredential.user.emailVerified,
          },
        });
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        console.error("Error logging in user:", errorCode, errorMessage);
        sentry.setUser({});
        set({
          authError: "Login failed. The email or password is incorrect",
          authState: "unauthenticated",
        });
      });
    return;
  },
  logout: () => {
    set({ authState: "signingOut" });
    auth.signOut().then(() => {
      sentry.setUser({});
      console.log("User signed out");
      set({
        ...initialSlice,
        user: undefined,
        allowedShops: [],
        authClaims: undefined,
      });
      window.location.reload();
    });
  },
  sendVerificationEmail: () => {
    set({ authError: undefined });
    const actionCodeSettings = {
      url: "https://app.shopherodashboard.com",
    };
    if (auth.currentUser) {
      sendEmailVerification(auth.currentUser, actionCodeSettings).then(() => {
        alert("Email verification sent! Please check your email.");
      });
    } else {
      set({ authError: "No user identified to send email verification link." });
    }
    return;
  },
  signup: async ({
    email,
    password,
    displayName,
    website,
    phone,
    shopName,
    tenantId,
    shopId,
    integrator,
  }: {
    email: string;
    password: string;
    displayName: string;
    website: string;
    phone: string;
    shopName: string;
    tenantId?: string;
    shopId?: string;
    integrator: Integrators;
  }) => {
    set({ authError: undefined });

    // 1. Create auth user in firebase
    // 2. Create shop in firestore
    // 3. Set user auth claims to admin

    const newUser = await authActions
      .createUserWithEmailAndPassword(email, password)
      .catch((e) => {
        const error =
          e.message === "Firebase: Error (auth/email-already-in-use)."
            ? "Email already in use"
            : e.message;

        set({
          authError: error,
        });
        toast({
          title: "Registration Error",
          description: error,
          variant: "destructive",
        });
      });

    if (!newUser) {
      throw new Error("Unable to create auth user");
    }

    const dbUser: Partial<User> = {
      id: newUser.user.uid,
      uid: newUser.user.uid,
      email: email,
      displayName: displayName,
      lastLoginTime: new Date(),
      role: "user",
    };
    await user.create(dbUser);
    await httpsCallables.registerShop({
      email,
      shopName,
      shopId,
      website,
      phone,
      tenantId,
      integrator,
    });

    const actionCodeSettings = {
      url: config.host ?? "https://app.shopherodashboard.com",
    };
    if (auth.currentUser) {
      await sendEmailVerification(auth.currentUser, actionCodeSettings).then(
        () => {
          toast({
            title: "Email Verification Sent",
            description: "Click the link in the email to verify your email",
          });
        }
      );
    } else {
      set({ authError: "No user identified to send email verification link." });
    }
    await new Promise((resolve) => setTimeout(resolve, 2000));
    return;
  },
  resetPassword: (email: string) => {
    set({ authError: undefined });
    return sendPasswordResetEmail(auth, email)
      .then(() => {
        alert("Password reset email sent!");
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        set({ authError: errorMessage });
        console.error(
          "Error sending password reset email:",
          errorCode,
          errorMessage
        );
      });
  },
});
