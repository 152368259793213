import {
  CollectionReference,
  DocumentData,
  DocumentReference,
  FirestoreDataConverter,
  FirestoreError,
  Query,
  Unsubscribe,
  WhereFilterOp,
  collection,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  limit,
  onSnapshot,
  orderBy,
  or,
  query,
  setDoc,
  updateDoc,
  where,
} from "firebase/firestore";
import { db } from "./db";
import { SearchOptions } from "@/types/v2";

/**
 * Handles a Firestore error by logging it to the console.
 *
 * @param {FirestoreError} error - The Firestore error to be handled.
 */
const onError = (error: FirestoreError) => {
  console.error(error);
};

/**
 * Firestore utility for interacting with Firestore documents.
 * @namespace
 */
export const firestore = {
  getRef: <T = DocumentData>(path: string) => {
    return doc(db, path) as DocumentReference<T>;
  },
  /**
   * Retrieve a Firestore document from the specified path.
   *
   * @param {string} path - The path to the Firestore document.
   * @param converter
   * @returns {Promise<T | undefined>} A Promise that resolves to the document data.
   */
  getDocument: async <T = DocumentData>(
    path: string,
    converter?: FirestoreDataConverter<T>
  ): Promise<T | undefined> => {
    const docRef = (
      converter ? doc(db, path).withConverter(converter) : doc(db, path)
    ) as DocumentReference<T>;
    const docSnap = await getDoc(docRef);
    return docSnap.data();
  },
  /**
   * Create a Firestore document at the specified path with the given data.
   *
   * @param {string} path - The path where the document should be created.
   * @param {T} data - The data to be stored in the document.
   * @param converter
   * @returns {Promise<T>} A Promise that resolves to the created document data.
   */
  createDocument: async <T = DocumentData>(
    path: string,
    data: T,
    converter?: FirestoreDataConverter<T>
  ): Promise<T> => {
    const docRef = (
      converter ? doc(db, path).withConverter(converter) : doc(db, path)
    ) as DocumentReference<T>;
    const dataWId = { id: docRef.id, ...data };
    await setDoc(docRef, dataWId);
    return dataWId;
  },

  deleteDocument: async (path: string) => {
    const docRef = doc(db, path);
    await deleteDoc(docRef);
    return;
  },

  /**
   * Update a Firestore document at the specified path with the given data.
   *
   * @param {string} path - The path of the document to be updated.
   * @param {T} data - The updated data for the document.
   * @param converter
   * @returns {Promise<T>} A Promise that resolves to the updated document data.
   */
  updateDocument: async <T = DocumentData>(
    path: string,
    data: T,
    converter?: FirestoreDataConverter<T>
  ): Promise<T> => {
    const docRef = (
      converter ? doc(db, path).withConverter(converter) : doc(db, path)
    ) as DocumentReference<T>;
    const dataWId = { id: docRef.id, ...data, _source: "client" };
    await updateDoc(docRef, dataWId);
    return dataWId;
  },
  queryDocuments: async <T = DocumentData>(
    path: string,
    searchParam: string,
    opsString: WhereFilterOp,
    equality: unknown,
    converter?: FirestoreDataConverter<T>,
    limitResults: number = 1000,
    orderByField?: string,
    orderDirection: "asc" | "desc" = "asc"
  ) => {
    let queryRef = query(
      collection(db, path),
      where(searchParam, opsString, equality)
    ) as Query<T>;

    queryRef = orderByField
      ? (query(
          queryRef,
          orderBy(orderByField, orderDirection),
          limit(limitResults)
        ) as Query<T>)
      : (query(queryRef, limit(limitResults)) as Query<T>);
    const queryRefwConv = converter
      ? queryRef.withConverter(converter)
      : queryRef;
    const querySnapshot = await getDocs(queryRefwConv);
    const docs: Array<T> = [];
    querySnapshot.forEach((doc) => docs.push({ ...doc.data() }));
    return docs;
  },
  listDocuments: async <T = DocumentData>(
    path: string,
    converter?: FirestoreDataConverter<T>,
    limitResults: number = 1000,
    orderByField?: string,
    orderDirection: "asc" | "desc" = "asc"
  ) => {
    const colRef = (
      converter
        ? collection(db, path).withConverter(converter)
        : collection(db, path)
    ) as Query<T>;
    const queryRef = orderByField
      ? (query(
          colRef,
          orderBy(orderByField, orderDirection),
          limit(limitResults)
        ) as Query<T>)
      : (query(colRef, limit(limitResults)) as Query<T>);
    const querySnapshot = await getDocs(queryRef);
    const docs: Array<T> = [];
    querySnapshot.forEach((doc) => docs.push({ ...doc.data() }));
    return docs;
  },

  /**
   * Subscribes to changes in a Firestore document and invokes a callback when the document changes.
   *
   * @param {string} path - The path to the Firestore document.
   * @param {(snapshot: DocumentSnapshot<DocumentData>) => void} callback - The callback function to be called when the document changes.
   * @param converter
   * @returns {Unsubscribe} A function that can be called to unsubscribe from further document changes.
   */
  subscribeDocument: <T = DocumentData>(
    path: string,
    callback: (doc: T | undefined) => void,
    converter?: FirestoreDataConverter<T>
  ): Unsubscribe => {
    const docRef = (
      converter ? doc(db, path).withConverter(converter) : doc(db, path)
    ) as DocumentReference<T>;
    return onSnapshot(docRef, (doc) => callback(doc.data()), onError);
  },

  /**
   * Subscribes to changes in a Firestore collection and invokes a callback when the documents change.
   *
   * @param {string} path - The path to the Firestore collection.
   * @param {(array: Array<DocumentData>) => void} callback - The callback function to be called when the documents change.
   * @param converter
   * @param limitResults
   * @param orderByField
   * @param orderDirection
   * @returns {Unsubscribe} A function that can be called to unsubscribe from further document changes.
   */
  subscribeCollection: <T = DocumentData>(
    path: string,
    callback: (docs: T[]) => void,
    converter?: FirestoreDataConverter<T>,
    limitResults: number = 1000,
    orderByField?: string,
    orderDirection: "asc" | "desc" = "asc"
  ): Unsubscribe => {
    const colRef = converter
      ? collection(db, path).withConverter(converter)
      : (collection(db, path) as CollectionReference<T>);
    const queryRef = orderByField
      ? (query(
          colRef,
          orderBy(orderByField, orderDirection),
          limit(limitResults)
        ) as Query<T>)
      : (query(colRef, limit(limitResults)) as Query<T>);
    return onSnapshot(
      queryRef,
      (querySnapshot) => {
        const docs = querySnapshot.docs.map((doc) => doc.data());
        callback(docs);
      },
      onError
    );
  },

  /**
   * Subscribes to changes in a Firestore collection and invokes a callback when the documents change.
   *
   * @param {string} path - The path to the Firestore collection.
   * @param {(array: Array<DocumentData>) => void} callback - The callback function to be called when the documents change.
   * @param searchParam
   * @param opsString
   * @param equality
   * @param converter
   * @param limitResults
   * @returns {Unsubscribe} A function that can be called to unsubscribe from further document changes.
   */
  subscribeCollectionWQuery: <T = DocumentData>(
    path: string,
    callback: (docs: T[]) => void,
    searchParam?: string,
    opsString?: WhereFilterOp,
    equality?: unknown,
    converter?: FirestoreDataConverter<T>,
    limitResults: number = 1000
  ): Unsubscribe => {
    const colRef = converter
      ? collection(db, path).withConverter(converter)
      : (collection(db, path) as CollectionReference<T>);
    if (searchParam && opsString && equality) {
      const queryRef = query(
        colRef,
        where(searchParam, opsString, equality),
        limit(limitResults)
      ) as Query<T>;
      return onSnapshot(queryRef, (snapshot) => {
        const docs = snapshot.docs.map((doc) => doc.data());
        callback(docs);
      });
    }
    const queryRef = query(colRef, limit(limitResults)) as Query<T>;
    return onSnapshot(queryRef, (snapshot) => {
      const docs = snapshot.docs.map((doc) => doc.data());
      callback(docs);
    });
  },

  subscribeCollectionWQueries: <T = DocumentData>(
    path: string,
    callback: (docs: T[]) => void,
    searchOptions?: SearchOptions<T>[],
    converter?: FirestoreDataConverter<T>,
    limitResults: number = 1000,
    orderByField?: string,
    orderDirection: "asc" | "desc" = "asc",
    onError?: (error: FirestoreError) => void
  ): Unsubscribe => {
    const colRef = converter
      ? collection(db, path).withConverter(converter)
      : (collection(db, path) as CollectionReference<T>);

    let baseQuery = query(colRef, orderBy("id")) as Query<T>;

    if (searchOptions) {
      baseQuery = query(
        baseQuery,
        or(
          ...searchOptions.map(({ searchParam, opsString, equality }) =>
            where(searchParam as string, opsString, equality)
          )
        )
      );
    }
    baseQuery = orderByField
      ? query(
          baseQuery,
          orderBy(orderByField, orderDirection),
          limit(limitResults)
        )
      : query(baseQuery, limit(limitResults));

    return onSnapshot(
      baseQuery,
      (snapshot) => {
        const docs = snapshot.docs.map((doc) => doc.data());
        callback(docs);
      },
      onError
    );
  },
};
