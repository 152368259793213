import tekmetricLogo from "@/assets/integrators/tekmetric.png";
import shopwareLogo from "@/assets/integrators/shopware.png";
import unknownLogo from "@/assets/integrators/unknown.png";
import shopheroLogo from "@/assets/ShdbLogo_small.png";
import { Integrators } from "@/types/v2";

/**
 * Retrieves the logo image associated with a specific integrator.
 *
 * @param {Integrators} integrator - The name of the integrator.
 * @return {string} The file path or reference to the appropriate integrator's logo image.
 */
export function getIntegratorImage(integrator: Integrators): string {
  switch (integrator) {
    case "tekmetric":
      return tekmetricLogo;
    case "shopware":
      return shopwareLogo;
    case "manual":
      return shopheroLogo;
    default:
      return unknownLogo;
  }
}
