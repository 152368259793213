import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { cn, firestoreToDateTime, getTextColor, isPast } from "@/lib/utils";
import { useFeature } from "flagged";
import { Card as CardType } from "@/types/v2";
import React, { useEffect, useMemo, useState } from "react";
import { useStore } from "@/stores/useStore";
import {
  DEFAULT_CARD_COLOR,
  DEFAULT_TIMER_VALUE,
  PROMISE_TIME_PAST_COLOR,
} from "@/lib/const";
import { CardFooter } from "@/components/Dashboard/components/Card/CardFooter";
import { CardHeader } from "@/components/Dashboard/components/Card/Card.Header";
import { CardMain } from "@/components/Dashboard/components/Card/Card.Main";
import { getCardSection } from "@/lib/cards";
import { useRoles } from "@/hooks/useRoles";

interface CardProps {
  cardId: string;
  ticker: number;
  isDragging?: boolean;
  tentative?: boolean;
}

export const Card = ({ cardId, ticker, isDragging, tentative }: CardProps) => {
  const shopId = useStore((s) => s.shopId);
  const shop = useStore((s) => s.shop);
  const timers = useStore((s) => s.timers);
  const timer = useMemo(
    () => (timers ? timers[cardId] : undefined),
    [cardId, timers]
  );
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id: cardId });
  const { isViewOnly } = useRoles();

  const [startTime, setStartTime] = useState<"stop" | number | null>(null);

  const isCardColorEnabled = useFeature("isCardColorEnabled");
  const isCardColorSyncEnabled = useFeature("isCardColorSyncEnabled");
  const isPromiseTimeEnabled = useFeature("isPromiseTimeEnabled");

  const [cardData, setCardData] = useState<CardType>();

  const { cards } = useStore();

  useEffect(() => {
    if (!timer) {
      if (typeof startTime === "number") setStartTime(null);
    }
    if (timer) {
      if (!startTime || startTime === "stop") {
        setStartTime(timer.timeStarted);
      }
    }
  }, [startTime, timer]);

  useEffect(() => {
    if (!shopId) return;
    setCardData(cards.find((card) => card.id === cardId));
  }, [cardId, shopId, cards]);

  const cardBackgroundColor = React.useMemo(() => {
    if (isPromiseTimeEnabled && isPast(cardData?.promisedTime)) {
      return PROMISE_TIME_PAST_COLOR;
    } else if (isCardColorSyncEnabled && cardData?.backgroundColor) {
      return cardData.backgroundColor;
    } else if (isCardColorEnabled && cardData?.color) {
      return cardData.color;
    } else if (isCardColorEnabled && cardData?.bgColor) {
      // TODO: REMOVE EVENTUALLY
      return cardData.bgColor;
    }

    return DEFAULT_CARD_COLOR;
  }, [
    cardData,
    isPromiseTimeEnabled,
    isCardColorSyncEnabled,
    isCardColorEnabled,
  ]);

  const getBackgroundColor = (excludeAutomation: boolean = false) => {
    const theColor = !isCardColorEnabled
      ? startTime !== null && startTime !== "stop"
        ? Date.now() - startTime >
          parseInt(cardData?.timer?.timeLimitInMinutes ?? DEFAULT_TIMER_VALUE) *
            60 *
            1000
          ? "#FF0000"
          : "#FFFF00"
        : cardBackgroundColor
      : cardBackgroundColor;

    if (
      automationActionActive() &&
      automationAction("color") &&
      cardData?.automationActionData &&
      !excludeAutomation
    ) {
      return cardData?.automationActionData;
    } else if (isPromiseTimeEnabled && isPast(cardData?.promisedTime)) {
      return PROMISE_TIME_PAST_COLOR;
    } else if (isCardColorSyncEnabled && cardData?.backgroundColor) {
      return cardData.backgroundColor;
    } else if (isCardColorEnabled && cardData?.color) {
      return cardData.color;
    }
    return theColor;
  };

  const automationActionActive = () => {
    const now = new Date().getTime();
    let startsAt = 0;
    if (cardData?.automationActionStartAt) {
      startsAt =
        firestoreToDateTime(cardData?.automationActionStartAt)?.getTime() ?? 0;
    }
    if (cardData?.automationActionExpireAt) {
      const expiresAt = firestoreToDateTime(
        cardData?.automationActionExpireAt
      )?.getTime();

      if (expiresAt && expiresAt <= now) {
        // The action has expired, return false
        return false;
      }
    }

    return !(startsAt && startsAt > now);
  };

  const automationAction = (action: "wiggle" | "color"): null | boolean => {
    if (!automationActionActive() || !cardData || !cardData?.automationAction) {
      return null;
    }

    switch (action) {
      case "wiggle":
        if (
          !isDragging &&
          ["wiggle", "colorAndWiggle"].includes(cardData.automationAction)
        ) {
          return true;
        }
        break;
      case "color":
        if (["color", "colorAndWiggle"].includes(cardData.automationAction)) {
          return true;
        }
        break;
      default:
        return null;
    }

    return null;
  };

  if (!shopId || !cardData || !shop) return null;

  return (
    <div
      ref={setNodeRef}
      className={cn(
        "transition-all cursor-auto flex flex-col rounded-md flex-1 text-sm min-w-[150px] max-w-[150px] items-start mb-1 ml-1 mr-1 shadow-2xl",
        isDragging && "rotate-6 shadow-2xl border opacity-75 scale-90",
        tentative && "shadow-2xl border opacity-75",
        automationAction("wiggle") && "animate-wiggle"
      )}
      style={{
        transform: CSS.Transform.toString(transform),
        transition,
        backgroundColor: getBackgroundColor(),
        color: getTextColor(getBackgroundColor()),
      }}
    >
      <div className="w-full p-0.5 flex flex-col flex-grow relative">
        <CardHeader
          disabled={isViewOnly}
          cardData={cardData}
          cardId={cardId}
          primaryLabel={getCardSection(cardData, shop, "primaryField")}
          dndAttributes={attributes}
          dndListeners={listeners}
        />
        {/* PRIMARY LABEL */}

        {/* Center of button */}
        <CardMain
          cardData={cardData}
          cardId={cardId}
          shop={shop}
          shopId={shopId}
          ticker={ticker}
          dndAttributes={attributes}
          dndListeners={listeners}
        />
      </div>

      <CardFooter
        disabled={isViewOnly}
        ticker={ticker}
        cardData={cardData}
        shopId={shopId}
        startTime={startTime}
        bgColor={getBackgroundColor(true)}
      />
    </div>
  );
};
