import {
  BuilderButton,
  BuilderButtonProps,
} from "@/components/Automations/BuilderButton";

export interface BuilderListProps {
  sections: { title: string; buttons: BuilderButtonProps[] }[];
  onSelection?: () => void;
}

export const BuilderList = ({ sections, onSelection }: BuilderListProps) => {
  return (
    <div className={"flex flex-col gap-4"}>
      {sections.map((section, index) => (
        <div key={index} className={"flex flex-col gap-2"}>
          <h3 className={"text-base text-tertiary"}>{section.title}</h3>
          {section.buttons.map((button, index) => (
            <BuilderButton
              key={index}
              {...button}
              onClick={(e) => {
                if (button.onClick) {
                  button.onClick(e);
                }
                if (onSelection) {
                  onSelection();
                }
              }}
            />
          ))}
        </div>
      ))}
    </div>
  );
};
