import { ComponentPropsWithoutRef, PropsWithChildren } from "react";
import { cn } from "@/lib/utils";

interface SupportLinkProps
  extends ComponentPropsWithoutRef<"a">,
    PropsWithChildren {}
export const SupportLink = ({
  className,
  children = "Support",
  ...props
}: SupportLinkProps) => {
  return (
    <a
      className={cn("text-accent-foreground", className)}
      href="https://www.shopherodashboard.com/support-request"
      target="_blank"
      {...props}
    >
      {children}
    </a>
  );
};
