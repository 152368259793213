import { BuilderTrigger } from "@/types/v2/Automation";

/**
 * Flattens a hierarchical list of triggers by removing nested conditional triggers
 * and producing a flat list of triggers.
 *
 * @param {BuilderTrigger[]} triggers - The array of triggers that may contain nested conditional triggers.
 * @return {BuilderTrigger[]} A flattened array of triggers with all nested triggers merged into the top-level array.
 */
export function flattenTriggers(triggers: BuilderTrigger[]): BuilderTrigger[] {
  let flatList: BuilderTrigger[] = [];

  for (const trigger of triggers) {
    flatList.push({
      ...trigger,
      conditionalTriggers: undefined, // We don't include nested triggers in the flat list
    });

    if (trigger.conditionalTriggers) {
      const nestedFlatList = flattenTriggers(trigger.conditionalTriggers);
      flatList = flatList.concat(nestedFlatList);
    }
  }

  return flatList;
}
