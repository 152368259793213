import { Conversation } from "@/components/MessageBoard/Conversation";
import { Conversation as ConversationModel } from "@/types/v2/Conversation";
import { Fragment, useEffect, useMemo, useState } from "react";
import { Message, ReactionType } from "@/types/v2/Message";
import { useUsersQueries } from "@/hooks/queries/useUserQuery";
import { User } from "@/types/v2";
import { MessageRepository } from "@/models/message";
import { firestoreToDateTime } from "@/lib/utils";
import { useStore } from "@/stores/useStore";
import { Spinner } from "@/components/Common/Spinner";
import { useUsersContext } from "@/providers/Users.provider";
import { ConfirmDialog } from "@/components/Dialogs/ConfirmDialog";
import { ClassNameValue } from "tailwind-merge";

export interface MessageBoardProps {
  conversation: ConversationModel;
  shopId: string;
  className?: ClassNameValue;
}

const initialMessage = "<p></p>";

export const MessageBoard = ({
  conversation,
  shopId,
  className,
}: MessageBoardProps) => {
  const conversationId = useMemo(() => conversation.id, [conversation]);
  const { user } = useStore();
  const [messages, setMessages] = useState<Message[]>([]);
  const [newMessage, setNewMessage] = useState<string>(initialMessage);
  const [isEditing, setIsEditing] = useState(false);
  const [isEditingId, setIsEditingId] = useState<string>();
  const [deleteId, setDeleteId] = useState<string>();
  const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);

  const messageSenders = useMemo(() => {
    const senderIds = messages.map((message) => message.senderId);
    const reactorIds = messages.flatMap((message) =>
      message.reactions.map((reaction) => reaction.userId)
    );
    return [...new Set([...senderIds, ...reactorIds])];
  }, [messages]);

  const { users, setUsers } = useUsersContext();
  const usersQueryResults = useUsersQueries(messageSenders);
  const queryUsers = usersQueryResults
    .map((result) => result.data)
    .filter(Boolean) as User[];

  useEffect(() => {
    if (queryUsers.length > 0) {
      setUsers(queryUsers);
    }
  }, [queryUsers]);

  useEffect(() => {
    if (!shopId || !conversationId) return;
    const unsubscribe = MessageRepository.subscribeAll(
      shopId,
      conversationId,
      (messages) =>
        setMessages(
          messages?.sort((a, b) =>
            (firestoreToDateTime(a.createdAt)?.getTime() ?? 0) >
            (firestoreToDateTime(b.createdAt)?.getTime() ?? 0)
              ? 1
              : -1
          ) ?? []
        )
    );
    return () => unsubscribe();
  }, [shopId, conversationId]);

  const handleSendMessage = () => {
    if (!shopId || !conversationId || !user || !newMessage) return;
    MessageRepository.create(shopId, conversationId, {
      type: "text",
      senderId: user.id,
      content: newMessage,
    }).finally(() => setNewMessage(initialMessage));
  };

  const handleUpdateMessage = () => {
    if (!shopId || !conversationId || !user || !newMessage || !isEditingId)
      return;
    MessageRepository.update(shopId, conversationId, isEditingId, {
      content: newMessage,
    }).finally(() => {
      setNewMessage(initialMessage);
      setIsEditing(false);
      setIsEditingId(undefined);
    });
  };

  const handleNewMessageChange = (value: string) => {
    setNewMessage(value);
  };

  const handleReactionClick = (messageId: string, reaction: ReactionType) => {
    if (!shopId || !conversationId || !user) return;
    const messageToUpdate = messages.find(
      (message) => message.id === messageId
    );
    if (!messageToUpdate) return;
    MessageRepository.addRemoveReaction(
      shopId,
      conversationId,
      messageToUpdate,
      user.id,
      reaction
    );
  };

  const handleEditClick = (messageId: string) => {
    const messageToUpdate = messages.find(
      (message) => message.id === messageId
    );
    if (!messageToUpdate) return;
    setIsEditing(true);
    setNewMessage(messageToUpdate.content);
    setIsEditingId(messageToUpdate.id);
  };

  const handleCancelEdit = () => {
    setIsEditing(false);
    setIsEditingId(undefined);
    setNewMessage(initialMessage);
  };

  const handleDeleteClick = (messageId: string) => {
    setConfirmDeleteOpen(true);
    setDeleteId(messageId);
  };

  const handleConfirmDelete = () => {
    if (!shopId || !conversationId || !user || !deleteId) return;
    MessageRepository.delete(shopId, conversationId, deleteId);
  };

  if (!user) {
    return <Spinner />;
  }

  return (
    <Fragment>
      <ConfirmDialog
        open={confirmDeleteOpen}
        setOpen={setConfirmDeleteOpen}
        message={"Delete message?"}
        onConfirm={handleConfirmDelete}
      />
      <Conversation
        conversation={conversation}
        messages={messages}
        userId={user.id}
        users={users}
        onChange={handleNewMessageChange}
        onSend={isEditing ? handleUpdateMessage : handleSendMessage}
        onReaction={handleReactionClick}
        onEdit={handleEditClick}
        onDiscard={isEditing ? handleCancelEdit : undefined}
        discardText={isEditing ? "Discard" : undefined}
        sendText={isEditing ? "Update" : undefined}
        value={newMessage}
        onDelete={handleDeleteClick}
        className={className}
      />
    </Fragment>
  );
};
